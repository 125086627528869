// same as on our tailwind-config
export const configResponsive = {
    xxs: 240,
    xs: 360,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    xxl: 1920
};
