import { calculatePoolRatio, getIsExoticPair } from "../../utils/pool.utils";
import { unixToSlot } from "../../utils/slots.utils";
import { Resolution } from "../generated/stats2.sdk";
export const transformBrambleAsset = (data)=>{
    return {
        ...data,
        assetId: data.id,
        dateListed: data.dateListed?.format ?? "",
        decimals: data.decimals,
        policyId: data.policyId,
        poolId: "",
        slotAdded: data.dateListed?.format ? unixToSlot(new Date(data.dateListed.format).getTime() / 1000) : 0,
        assetName: data.assetName,
        logo: data.logo,
        ticker: data.ticker ?? data.name,
        sources: data.metadata?.map(({ __typename })=>__typename) ?? [],
        // @ts-expect-error: AssetFragmentFragment doesn't have description field
        description: data.description
    };
};
export const transformStatsAsset = (data)=>{
    return {
        ...data,
        id: data.assetId,
        dateListed: {
            format: data.dateListed
        },
        decimals: data.decimals,
        policyId: data.policyId,
        name: data.ticker || data.assetName || "",
        logo: data.logo,
        ticker: data.ticker,
        metadata: data.sources?.map((value)=>({
                __typename: value
            })) ?? [],
        // @ts-expect-error: AssetFragmentFragment doesn't have description field
        description: data.description,
        assetName: data.assetName
    };
};
export const transformBramblePool = (data, adaUsdPrice = "0")=>{
    const assetA = transformBrambleAsset(data.assetA);
    const assetB = transformBrambleAsset(data.assetB);
    const isExoticPair = getIsExoticPair({
        assetA,
        assetB
    });
    const poolRatio = calculatePoolRatio({
        assetA,
        assetB,
        quantityA: data.current?.quantityA.quantity,
        quantityB: data.current?.quantityB.quantity
    });
    const priceToday = String(Number(adaUsdPrice) * (poolRatio?.toNumber() ?? 0));
    return {
        askFee: data.askFee,
        assetA: {
            ...assetA,
            ...!isExoticPair && {
                priceToday
            }
        },
        assetB: {
            ...assetB,
            ...!isExoticPair && {
                priceToday
            }
        },
        assetID: data.id,
        assetLP: transformBrambleAsset(data.assetLP),
        bidFee: data.bidFee,
        feeManagerId: data.feeManagerId,
        ident: data.id,
        marketOpen: data.marketOpen,
        name: `${data.assetA.ticker}-${data.assetB.ticker}`,
        quantityA: data.current?.quantityA.quantity,
        quantityB: data.current?.quantityB.quantity,
        quantityLP: data.current?.quantityLP.quantity,
        rewards: [],
        version: data.version
    };
};
export const getOrderOpFromBramble = (data)=>{
    const { details, pool } = data;
    const { __typename } = details;
    switch(__typename){
        case "Deposit":
            {
                const { offerDeposit, receivedDeposit } = data.details;
                const depositMixedQuantityA = offerDeposit.find(({ asset })=>asset.id === pool?.assetA.id)?.quantity;
                const depositMixedQuantityB = offerDeposit.find(({ asset })=>asset.id === pool?.assetB.id)?.quantity;
                return {
                    __typename: "OpDepositMixed",
                    depositMixedLq: receivedDeposit?.quantity,
                    depositMixedQuantityA,
                    depositMixedQuantityB
                };
            }
        case "Withdraw":
            {
                const { amountWithdraw, receivedWithdraw } = data.details;
                return {
                    __typename: "OpWithdraw",
                    withdrawLq: amountWithdraw.quantity,
                    withdrawQuantityA: receivedWithdraw.find(({ asset })=>asset.id === pool?.assetA?.id)?.quantity,
                    withdrawQuantityB: receivedWithdraw.find(({ asset })=>asset.id === pool?.assetB?.id)?.quantity
                };
            }
        default:
        case "Swap":
            const { offerSwap, receivedSwap, minimumSwap } = data.details;
            const swapQuantityA = offerSwap.asset.id === pool?.assetA.id ? offerSwap.quantity : null;
            const swapQuantityB = offerSwap.asset.id === pool?.assetB.id ? offerSwap.quantity : null;
            return {
                __typename: "OpSwap",
                swapGot: receivedSwap?.quantity,
                swapQuantityA,
                swapQuantityB,
                swapWant: minimumSwap.quantity
            };
    }
};
export const getOutcomeFromBramble = (data)=>{
    const { details, pool } = data;
    let quantityA = null;
    let quantityB = null;
    let quantityLP = null;
    switch(details.__typename){
        case "Deposit":
            const { receivedDeposit } = details;
            quantityLP = receivedDeposit?.quantity;
            break;
        case "Withdraw":
            const { receivedWithdraw } = details;
            quantityA = receivedWithdraw.find(({ asset })=>asset.id === pool?.assetA.id)?.quantity;
            quantityB = receivedWithdraw.find(({ asset })=>asset.id === pool?.assetB.id)?.quantity;
            break;
        default:
        case "Swap":
            const { receivedSwap } = details;
            quantityA = receivedSwap?.asset.id === pool?.assetA.id ? receivedSwap?.quantity : null;
            quantityB = receivedSwap?.asset.id === pool?.assetB.id ? receivedSwap?.quantity : null;
    }
    return {
        feeA: null,
        feeB: null,
        quantityA,
        quantityB,
        quantityLP,
        price: {
            unit: "",
            value: ""
        }
    };
};
export const getSpentReasonFromBramble = (outcome)=>{
    if (outcome === "Executed") return Resolution.Scooped;
    return Resolution.Canceled;
};
