import { DEFAULT_DURATION, EXIT_DURATION } from "./default.motions";
export const joyrideTooltipFadeInMotion = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
            duration: DEFAULT_DURATION
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: EXIT_DURATION
        }
    }
};
export const joyrideTooltipComponentMotion = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
            type: "spring",
            duration: DEFAULT_DURATION,
            staggerChildren: 0.125,
            delayChildren: 0.25
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: EXIT_DURATION
        }
    }
};
export const joyrideButtonMotion = {
    ...joyrideTooltipComponentMotion,
    animate: {
        ...joyrideTooltipComponentMotion.animate,
        transition: {
            ...joyrideTooltipComponentMotion.animate.transition,
            delayChildren: 0
        }
    }
};
