export const getImagePathByTheme = (path, isDarkMode = false, separator = "_", fileType)=>`${path}${isDarkMode ? `${separator}dark` : ""}.${fileType ?? "png"}`;
/**
 * Replaces the ifps protocol with https
 * @param path - The path to the image
 * @returns - The path to the image with the ifps protocol replaced with https
 */ export const getIpfsImagePath = (path)=>{
    if (!path) return undefined;
    return path.replace("ipfs://", "https://ipfs.io/ipfs/");
};
/**
 * Returns the size of the image to use for the no data table image
 * @param responsive - The responsive object returned from our `useResponsive` hook from @sundaeswap/react-hooks
 * @returns - The size of the image to use for the no data table image
 */ export const getNoDataTableImageSize = (responsive)=>{
    if (responsive.lg) {
        return 275; // 275px
    }
    return 125; // 125px
};
