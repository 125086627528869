export var ETransactionState;
(function(ETransactionState) {
    ETransactionState["initial"] = "initial";
    ETransactionState["preview"] = "preview";
    ETransactionState["previewCancelled"] = "previewCancelled";
    ETransactionState["submitting"] = "submitting";
    ETransactionState["submittingCancelled"] = "submittingCancelled";
    ETransactionState["success"] = "success";
    ETransactionState["error"] = "error";
    ETransactionState["reset"] = "reset";
})(ETransactionState || (ETransactionState = {}));
