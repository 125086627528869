import { useQuery } from "@tanstack/react-query";
import { getStats2Sdk } from "../gql/client/statsSdk";
export const useAdaData = ()=>useQuery({
        queryKey: [
            "adaData"
        ],
        queryFn: async ()=>{
            const { getAdaPrice } = await getStats2Sdk();
            const { adaPrice } = await getAdaPrice();
            return {
                usd: Number(adaPrice)
            };
        },
        staleTime: 1000 * 60 * 10,
        refetchOnWindowFocus: false
    });
