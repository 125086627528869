import { useWalletLoadingState, useWalletObserver } from "@sundaeswap/wallet-lite";
import { useMemo } from "react";
export const useIsWalletConnected = ()=>{
    const { willAutoConnect } = useWalletObserver();
    const { ready } = useWalletLoadingState();
    return useMemo(()=>ready || willAutoConnect, [
        ready,
        willAutoConnect
    ]);
};
