import { ComparisonSteps } from "./comparison";
import { CreatePoolSteps } from "./create-pool";
import { MigrationSteps } from "./migrate";
export const V3 = {
    Comparison: {
        Steps: ComparisonSteps
    },
    CreatePool: {
        Steps: CreatePoolSteps
    },
    Migration: {
        Steps: MigrationSteps
    }
};
