/**
 * Resolves the type of Prismic link and returns a URL based on the link type.
 *
 * @param {LinkField} link - The Prismic link to be resolved.
 *
 * @returns {string | undefined} The resolved URL if the link can be resolved, undefined otherwise.
 */ export const prismicLinkResolver = (link)=>{
    let typedLink;
    if (link.uid) {
        typedLink = link;
        if (typedLink.type === "blog_post") {
            return `https://sundae.fi/posts/${typedLink.uid}`;
        }
    }
    if (link.url) {
        typedLink = link;
        return typedLink.url;
    }
};
