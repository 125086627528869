import { useCountersApi } from "@sundaeswap/react-hooks";
import { useCallback } from "react";
import useAppLocation from "./router/useAppLocation";
const defaultCountersApiOptions = {
    app: "sundaeswap",
    company: "sundaelabs",
    network: window.__APP_CONFIG.envName === "mainnet" ? "mainnet" : "preview"
};
/**
 * Custom hook to handle event tracking using the counters API.
 * This hook wraps the useCountersApi hook, adding a feature flag and a hostname check
 * to conditionally allow or disallow tracking. The tracking is not performed if
 * it's not enabled by the feature flag or if the current hostname is "localhost".
 *
 * @returns {Function} - A callback function that accepts options for the useCountersApi
 *                       call and performs the tracking action if the conditions are met.
 *                       The options should match the IUseCountersApiOptions type.
 */ export const useEventTracking = ()=>{
    /**
   * Flag that sets whether we should perform the tracking action or not.
   * Disabled for localhost and preview/dev environments.
   */ const allowTracking = window.location.hostname !== "localhost" && process.env.NODE_ENV !== "development";
    const { pathname } = useAppLocation();
    const handleCountersApi = useCountersApi();
    const handleEventTracking = useCallback((options)=>{
        if (allowTracking) {
            handleCountersApi({
                ...defaultCountersApiOptions,
                ...options,
                url: pathname.replace("/", "")
            });
        }
    }, [
        allowTracking,
        pathname,
        handleCountersApi
    ]);
    return handleEventTracking;
};
