export var ENewsActions;
(function(ENewsActions) {
    /* -------------------------------------------------------------------------------------------------
   * Array of news ids that are retrieved from the CMS. Used to track which news items have been read.
   * -----------------------------------------------------------------------------------------------*/ ENewsActions["SET_READ_NEWS_ITEM"] = "SET_READ_NEWS_ITEM";
    ENewsActions["SET_UNREAD_NEWS_ITEM"] = "SET_UNREAD_NEWS_ITEM";
    ENewsActions["SET_READ_ALL_NEWS_ITEMS"] = "SET_READ_ALL_NEWS_ITEMS";
    ENewsActions["SET_OPEN_NEWS_ITEM_ID"] = "SET_OPEN_NEWS_ITEM_ID";
    ENewsActions["SET_FORCE_OPENED_NEWS_ID"] = "SET_FORCE_OPENED_NEWS_ID";
})(ENewsActions || (ENewsActions = {}));
