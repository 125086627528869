import { AssetAmount } from "@sundaeswap/asset";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { DEFAULT_DECIMALS } from "../constants/cardano.constants";
export const truncateString = (str, truncateFrom = 8, truncateUntil = 2)=>{
    if (!str || SundaeUtils.isAdaAsset({
        assetId: str,
        decimals: 6
    })) {
        return "";
    }
    if (str.length <= truncateFrom) return str;
    const truncated = `${str.substring(0, truncateFrom)}…${str.substring(str.length - truncateUntil)}`;
    return truncated.length >= str.length ? str : truncated;
};
export const bigintToString = (output, decimals = DEFAULT_DECIMALS)=>new AssetAmount(output, decimals).value.toString();
export const maskText = (text)=>text ? "*".repeat(text.length) : "";
/**
 * This function takes a transaction ID as an input, and returns a modified version where each digit is replaced with a corresponding letter.
 * The mapping from digits to letters is defined in the `digitToLetter` object. Specifically, '0' maps to 'a', '1' maps to 'b', and so on, up to '9' mapping to 'j'.
 *
 * @param {string} id - The original transaction ID, expected to be a string consisting of any characters, but specifically digits are replaced.
 *
 * @returns {string} - The function returns a new string which is a copy of the input string, but with each digit replaced by its corresponding letter according to the `digitToLetter` mapping. If the input 'id' is null or undefined, it returns undefined.
 */ export const formatTransactionId = (id)=>{
    const digitToLetter = {
        "0": "a",
        "1": "b",
        "2": "c",
        "3": "d",
        "4": "e",
        "5": "f",
        "6": "g",
        "7": "h",
        "8": "i",
        "9": "j"
    };
    return id?.replace(/\d/g, (match)=>digitToLetter[match]);
};
/**
 * Converts `BigInt` values in an object to strings for JSON stringification.
 * This function is intended to be used as a replacer function in `JSON.stringify()`.
 * It ensures that `BigInt` values are converted to strings with 'n' appended to indicate BigInt.
 *
 * @param {string} key - The key associated with the value being stringified.
 * @param {any} value - The value to be inspected and potentially transformed.
 * @returns {any} - The transformed value suitable for JSON stringification.
 */ export const stringifyBigInt = (_key, value)=>{
    return typeof value === "bigint" ? `${value.toString()}n` : value;
};
