import { ELocalControlCenterStateActions, EPersistentControlCenterStateActions } from "./types";
/* -------------------------------------------------------------------------------------------------
 * Persistent Control Center Actions
 * -----------------------------------------------------------------------------------------------*/ const startGuide = (currentGuideId, dispatch)=>{
    dispatch({
        type: EPersistentControlCenterStateActions.START_GUIDE,
        payload: {
            currentGuideId
        }
    });
};
const openGuidesOnMount = (openGuidesOnMount, dispatch)=>{
    dispatch({
        type: EPersistentControlCenterStateActions.OPEN_GUIDES_ON_MOUNT,
        payload: {
            openGuidesOnMount
        }
    });
};
const completeGuide = (currentGuideId, completed, dispatch)=>{
    dispatch({
        type: EPersistentControlCenterStateActions.COMPLETE_GUIDE,
        payload: {
            currentGuideId,
            completed
        }
    });
};
const closeGuide = (currentGuideId, completed, stepIndex, dispatch)=>{
    dispatch({
        type: EPersistentControlCenterStateActions.CLOSE_GUIDE,
        payload: {
            currentGuideId,
            completed,
            stepIndex
        }
    });
};
const goToStep = (currentGuideId, stepIndex, dispatch)=>{
    dispatch({
        type: EPersistentControlCenterStateActions.HANDLE_STEP,
        payload: {
            currentGuideId,
            stepIndex
        }
    });
};
const setIsEveryGuideCompleted = (isEveryChapterCompleted, dispatch)=>{
    dispatch({
        type: EPersistentControlCenterStateActions.SET_IS_EVERY_CHAPTER_COMPLETED,
        payload: {
            isEveryChapterCompleted
        }
    });
};
/* -------------------------------------------------------------------------------------------------
 * Local Control Center Actions
 * -----------------------------------------------------------------------------------------------*/ const loadChapters = (chapters, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.LOAD_CHAPTERS,
        payload: {
            chapters
        }
    });
};
const runGuide = (runGuide, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.RUN_GUIDE,
        payload: {
            runGuide
        }
    });
};
const setActiveChapter = (activeChapter, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SET_ACTIVE_CHAPTER,
        payload: {
            activeChapter
        }
    });
};
const setActiveGuideId = (activeGuideId, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SET_ACTIVE_GUIDE,
        payload: {
            activeGuideId
        }
    });
};
const setWithAnimationDelay = (withAnimationDelay, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SET_WITH_ANIMATION_DELAY,
        payload: {
            withAnimationDelay
        }
    });
};
const setIsNextButtonDisabled = (isNextButtonDisabled, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SET_IS_NEXT_BUTTON_DISABLED,
        payload: {
            isNextButtonDisabled
        }
    });
};
const setLastClosedGuideItemId = (lastClosedGuideItemId, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SET_LAST_CLOSED_GUIDE_ID,
        payload: {
            lastClosedGuideItemId
        }
    });
};
const showGuides = (showGuides, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_GUIDES,
        payload: {
            showGuides
        }
    });
};
const showGuideCloseUI = (showGuideCloseUI, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_GUIDE_CLOSE_UI,
        payload: {
            showGuideCloseUI
        }
    });
};
const showGuidesLoadingAnimation = (showLoadingAnimation, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_LOADING_ANIMATION_GUIDES,
        payload: {
            showLoadingAnimation
        }
    });
};
const showHelpCenter = (showHelpCenter, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_HELP_CENTER,
        payload: {
            showHelpCenter
        }
    });
};
const showInbox = (showInbox, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_INBOX,
        payload: {
            showInbox
        }
    });
};
const showSuccessAnimation = (showSuccessAnimation, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_SUCCESS_ANIMATION,
        payload: {
            showSuccessAnimation
        }
    });
};
const showToolbar = (showToolbar, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SHOW_TOOLBAR,
        payload: {
            showToolbar
        }
    });
};
const setLastClosedNewsId = (lastClosedNewsId, dispatch)=>{
    dispatch({
        type: ELocalControlCenterStateActions.SET_LAST_CLOSED_NEWS_ID,
        payload: {
            lastClosedNewsId
        }
    });
};
const LocalControlCenterActions = {
    guides: {
        loadChapters,
        runGuide,
        setActiveChapter,
        setActiveGuideId,
        setIsNextButtonDisabled,
        setLastClosedGuideItemId,
        showGuideCloseUI,
        showGuidesLoadingAnimation,
        showSuccessAnimation
    },
    news: {
        setLastClosedNewsId
    },
    setWithAnimationDelay,
    showGuides,
    showHelpCenter,
    showInbox,
    showToolbar
};
const PersistentControlCenterActions = {
    guides: {
        closeGuide,
        completeGuide,
        goToStep,
        openGuidesOnMount,
        setIsEveryChapterCompleted: setIsEveryGuideCompleted,
        startGuide
    }
};
export { LocalControlCenterActions, PersistentControlCenterActions };
