import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { joyrideIds, joyrideSelectors } from "../../../../constants/joyride.constants";
import { paths } from "../../../../pages/routes";
import { EGuideChapter } from "../../../../stores/ControlCenterContext";
export const useYieldFarmingGuideJoyride = ()=>{
    const { t } = useI18N("joyride");
    return useMemo(()=>({
            rank: 1,
            description: t("yieldFarming.description"),
            title: t("yieldFarming.title"),
            chapter: EGuideChapter.YIELD_FARMING,
            joyride: {
                id: joyrideIds.yieldFarming,
                steps: [
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step1.title"),
                        content: t("yieldFarming.guides.steps.step1.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.yieldFarming.stats}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step2.title"),
                        content: t("yieldFarming.guides.steps.step2.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.yieldFarming.positions}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step3.title"),
                        content: t("yieldFarming.guides.steps.step3.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.yieldFarming.leaderboard}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step4.title"),
                        content: t("yieldFarming.guides.steps.step4.description"),
                        placement: "right",
                        target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.pool}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step5.title"),
                        content: t("yieldFarming.guides.steps.step5.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.hra}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step6.title"),
                        content: t("yieldFarming.guides.steps.step6.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.communityDelegation}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step7.title"),
                        content: t("yieldFarming.guides.steps.step7.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.tvl}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step8.title"),
                        content: t("yieldFarming.guides.steps.step8.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.volume24h}"]`
                    },
                    {
                        entryUrl: paths.yieldFarming,
                        title: t("yieldFarming.guides.steps.step10.title"),
                        content: t("yieldFarming.guides.steps.step10.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.liquidity.actionsMenu}"]`
                    }
                ]
            }
        }), [
        t
    ]);
};
