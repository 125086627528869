import { useWalletObserver } from "@sundaeswap/wallet-lite";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useCustomCompareEffect } from "use-custom-compare";
import { useFarmPositions, useFarmsHistory } from "../../../gql/hooks/farms.query";
import { PortfolioActions } from "../actions";
import { usePortfolioContext } from "../context";
export const useFarms = ()=>{
    const { mainAddress } = useWalletObserver();
    const { dispatch } = usePortfolioContext();
    const { data: farmPositions, isFetching: isFetchingFarmPositions, isFetched: isFetchedFarmPositions } = useFarmPositions({
        address: mainAddress ?? "",
        pageSize: 1000
    });
    const { data: farmsHistory, isFetching: isFetchingFarmsHistory, isFetched: isFetchedFarmsHistory } = useFarmsHistory({
        address: mainAddress ?? "",
        pageSize: 1000
    });
    const isFetching = isFetchingFarmPositions || isFetchingFarmsHistory;
    const isFetched = isFetchedFarmPositions && isFetchedFarmsHistory;
    /// Set positions.
    useCustomCompareEffect(()=>{
        PortfolioActions.farms.setFarmPositions(farmPositions?.positions?.items, dispatch);
    }, [
        farmPositions?.positions?.items,
        dispatch
    ], isEqual);
    /// Set history.
    useCustomCompareEffect(()=>{
        PortfolioActions.farms.setFarmsHistory(farmsHistory?.items, dispatch);
    }, [
        farmsHistory?.items,
        dispatch
    ], isEqual);
    /// Set isFetched farms.
    useEffect(()=>{
        PortfolioActions.farms.setIsFetchedFarms(isFetched, dispatch);
    }, [
        isFetched,
        dispatch
    ]);
    /// Set isFetching farms.
    useEffect(()=>{
        PortfolioActions.farms.setIsFetchingFarms(isFetching, dispatch);
    }, [
        isFetching,
        dispatch
    ]);
};
