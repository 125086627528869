import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { MOCK_V3_ASSET_B_ID, SUNDAE_METADATA, readableAdaAssetId } from "../../../../constants/cardano.constants";
import { joyrideIds, joyrideSelectors } from "../../../../constants/joyride.constants";
import { paths } from "../../../../pages/routes";
import { EGuideChapter } from "../../../../stores/ControlCenterContext";
import { getEnv } from "../../../../utils/network.utils";
export const useExchangeGuideJoyride = ()=>{
    const { t } = useI18N("joyride");
    const network = getEnv();
    const defaultStepConfig = useMemo(()=>{
        return {
            entryUrl: paths.exchange,
            entrySearchParams: {
                given: readableAdaAssetId,
                taken: network === "mainnet" ? SUNDAE_METADATA.assetId : MOCK_V3_ASSET_B_ID
            }
        };
    }, [
        network
    ]);
    return useMemo(()=>({
            rank: 1,
            description: t("exchange.description"),
            title: t("exchange.title"),
            chapter: EGuideChapter.EXCHANGE,
            joyride: {
                id: joyrideIds.trade.exchangeMode,
                steps: [
                    {
                        ...defaultStepConfig,
                        title: t("exchange.guides.steps.step1.title"),
                        content: t("exchange.guides.steps.step1.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.tabs}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step2.title"),
                        content: t("exchange.guides.steps.step2.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.assetInputs}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step3.title"),
                        content: t("exchange.guides.steps.step3.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.priceChart}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step4.title"),
                        content: t("exchange.guides.steps.step4.description"),
                        placement: "right",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.transactionSettings}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step5.title"),
                        content: t("exchange.guides.steps.step5.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.maxButton}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step6.title"),
                        content: t("exchange.guides.steps.step6.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.orderTypeSelect}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step7.title"),
                        content: t("exchange.guides.steps.step7.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.liquidityPoolSelect}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step8.title"),
                        content: t("exchange.guides.steps.step8.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.poolStats}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step9.title"),
                        content: t("exchange.guides.steps.step9.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.assetMetadata}"]`
                    },
                    {
                        ...defaultStepConfig,
                        entryUrl: paths.exchange,
                        title: t("exchange.guides.steps.step10.title"),
                        content: t("exchange.guides.steps.step10.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.exchange.openOrdersTable}"]`
                    }
                ]
            }
        }), [
        defaultStepConfig,
        t
    ]);
};
