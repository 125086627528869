import { EPortfolioActions } from "./types";
export const portfolioReducer = (prevState, action)=>{
    let newState;
    switch(action.type){
        case EPortfolioActions.SET_ACTIVE_TAB_TITLE:
            {
                const { activeTabTitle } = action.payload;
                newState = {
                    ...prevState,
                    derived: {
                        ...prevState.derived,
                        activeTabTitle
                    }
                };
                break;
            }
        case EPortfolioActions.SET_IS_SIDEBAR_COLLAPSED:
            {
                const { isSidebarCollapsed } = action.payload;
                newState = {
                    ...prevState,
                    derived: {
                        ...prevState.derived,
                        isSidebarCollapsed
                    }
                };
                break;
            }
        case EPortfolioActions.SET_PORTFOLIO_BALANCE_VALUES:
            {
                const { portfolioBalanceValues } = action.payload;
                newState = {
                    ...prevState,
                    derived: {
                        ...prevState.derived,
                        portfolioBalanceValues
                    }
                };
                break;
            }
        case EPortfolioActions.SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA:
            {
                const { totalPortfolioBalanceInAda } = action.payload;
                newState = {
                    ...prevState,
                    derived: {
                        ...prevState.derived,
                        totalPortfolioBalanceInAda
                    }
                };
                break;
            }
        case EPortfolioActions.SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES:
            {
                const { isLoadingPortfolioBalanceValues } = action.payload;
                newState = {
                    ...prevState,
                    derived: {
                        ...prevState.derived,
                        isLoadingPortfolioBalanceValues
                    }
                };
                break;
            }
        case EPortfolioActions.SET_SELECTED_ASSET:
            {
                const { selectedAsset } = action.payload;
                newState = {
                    ...prevState,
                    tabs: {
                        ...prevState.tabs,
                        assets: {
                            ...prevState.tabs?.assets,
                            selectedAsset
                        }
                    }
                };
                break;
            }
        /* -------------------------------------------------------------------------------------------------
     * FARMS
     * -----------------------------------------------------------------------------------------------*/ case EPortfolioActions.SET_FARM_POSITIONS:
            {
                const { positions } = action.payload;
                newState = {
                    ...prevState,
                    farms: {
                        ...prevState.farms,
                        positions
                    }
                };
                break;
            }
        case EPortfolioActions.SET_FARMS_HISTORY:
            {
                const { history } = action.payload;
                newState = {
                    ...prevState,
                    farms: {
                        ...prevState.farms,
                        history
                    }
                };
                break;
            }
        /* -------------------------------------------------------------------------------------------------
     * OPS
     * -----------------------------------------------------------------------------------------------*/ case EPortfolioActions.SET_ALL_ORDERS:
            {
                const { allOrders } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        allOrders
                    }
                };
                break;
            }
        case EPortfolioActions.SET_FORMATTED_ORDERS:
            {
                const { formattedOrders } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        formattedOrders
                    }
                };
                break;
            }
        case EPortfolioActions.SET_OPS_OPEN:
            {
                const { opsOpen } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        opsOpen
                    }
                };
                break;
            }
        case EPortfolioActions.SET_OPS_HISTORY:
            {
                const { opsHistory } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        opsHistory
                    }
                };
                break;
            }
        case EPortfolioActions.SET_IS_FETCHED_OPS:
            {
                const { isFetchedOps } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        isFetchedOps
                    }
                };
                break;
            }
        case EPortfolioActions.SET_IS_FETCHING_OPS:
            {
                const { isFetchingOps } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        isFetchingOps
                    }
                };
                break;
            }
        case EPortfolioActions.SET_IS_REFETCHING_OPS:
            {
                const { isRefetchingOps } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        isRefetchingOps
                    }
                };
                break;
            }
        case EPortfolioActions.SET_ORDERS_SEARCH_VALUE:
            {
                const { searchValue } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        search: {
                            value: searchValue
                        }
                    }
                };
                break;
            }
        case EPortfolioActions.SET_ORDER_STATUS_FILTER_ITEMS:
            {
                const { items } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        filters: {
                            ...prevState.orders?.filters,
                            orderStatus: {
                                ...prevState.orders?.filters?.orderStatus,
                                items
                            }
                        }
                    }
                };
                break;
            }
        case EPortfolioActions.SET_ORDER_STATUS_FILTER_VALUE:
            {
                const { value } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        filters: {
                            ...prevState.orders?.filters,
                            orderStatus: {
                                ...prevState.orders?.filters?.orderStatus,
                                value
                            }
                        }
                    }
                };
                break;
            }
        case EPortfolioActions.SET_ORDERS_TABLE_COLUMN_WIDTHS:
            {
                const { value } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        ordersTableColumnWidths: value
                    }
                };
                break;
            }
        case EPortfolioActions.SET_ORDERS_TABLE_HEADERS:
            {
                const { value } = action.payload;
                newState = {
                    ...prevState,
                    orders: {
                        ...prevState.orders,
                        ordersTableHeaders: value
                    }
                };
                break;
            }
        default:
            newState = prevState;
    }
    return newState;
};
