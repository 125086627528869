import { EContractVersion } from "@sundaeswap/core";
import { EOrderFlowState, EOrderType } from "./types";
export const defaultOrderState = {
    assets: {},
    derived: {
        givenExceedsBalance: false,
        takenExceedsReserves: false
    },
    flowData: {
        orderConsent: {
            checked: false,
            i18nKey: undefined
        },
        flowState: EOrderFlowState.initial,
        isUpdate: false,
        orderType: EOrderType.market,
        syncSearch: true,
        syncPool: true,
        fetchingPool: false,
        contractVersion: EContractVersion.V1
    }
};
