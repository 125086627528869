import { colors } from "@sundaeswap/tailwind-config";
const primaryColor = colors.primary["DEFAULT"];
const secondaryColor = colors.secondary["DEFAULT"];
/**
 * Converts a hex color string to an array of RGB values.
 *
 * @param {string} hex - The hex color string.
 * @returns {[number, number, number]} - An array containing the RGB values.
 */ export const hexToRGB = (hex)=>{
    hex = hex.charAt(0) === "#" ? hex.slice(1) : hex;
    if (hex.length === 3) {
        hex = hex.split("").map((char)=>char + char).join("");
    }
    const bigint = parseInt(hex, 16);
    const r = bigint >> 16 & 255;
    const g = bigint >> 8 & 255;
    const b = bigint & 255;
    return [
        r,
        g,
        b
    ];
};
function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}
export function randomColorBetween(color1, color2) {
    // Convert hex to RGB
    const [r1, g1, b1] = hexToRGB(color1);
    const [r2, g2, b2] = hexToRGB(color2);
    // Generate a random factor
    const factor = Math.random();
    // Interpolate between the two colors
    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));
    // Convert the result back to hex
    return rgbToHex(r, g, b);
}
/**
 * Converts a string (mainly used for addresses) into a normalized hash factor between 0 and 1.
 * This function hashes the input string into a 32-bit integer, then normalizes
 * this hash to a value between 0 and 1 to be used as a factor in calculations.
 *
 * @param {string} address The input string to hash, typically representing some unique identifier.
 * @returns {number} A normalized hash value between 0 and 1.
 */ export function hashStringToFactor(address) {
    let hash = 0;
    for(let i = 0; i < address.length; i++){
        const char = address.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    const maxInt = 2 ** 31 - 1; // Maximum positive value for a 32bit signed binary integer
    // Normalize the hash to a value between 0 and 1
    return Math.abs(hash) / maxInt;
}
export function randomColorBetweenWithAddress(address, color1, color2) {
    const [r1, g1, b1] = hexToRGB(color1);
    const [r2, g2, b2] = hexToRGB(color2);
    const factor = hashStringToFactor(address); // Use hash of address as the factor
    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));
    return rgbToHex(r, g, b);
}
/**
 * Converts RGB values to an array of HSL values.
 *
 * @param {number} r - The red value.
 * @param {number} g - The green value.
 * @param {number} b - The blue value.
 * @returns {[number, number, number]} - An array containing the HSL values.
 */ export const rgbToHSL = (r, g, b)=>{
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max === min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max){
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
            default:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    return [
        h,
        s,
        l
    ];
};
/**
 * Interpolates between two HSL colors.
 *
 * @param {[number, number, number]} color1 - The starting HSL color.
 * @param {[number, number, number]} color2 - The ending HSL color.
 * @param {number} factor - The interpolation factor.
 * @returns {string} - The interpolated HSL color.
 */ export const interpolateHSL = (color1, color2, factor)=>{
    const [h1, s1, l1] = color1;
    const [h2, s2, l2] = color2;
    const h = Math.round((h1 + factor * (h2 - h1)) * 360);
    const s = ((s1 + factor * (s2 - s1)) * 100).toFixed(0);
    const l = ((l1 + factor * (l2 - l1)) * 100).toFixed(0);
    return `hsl(${h}, ${s}%, ${l}%)`;
};
/**
 * Calculates a hash code for a given string.
 *
 * @param {string} str - The input string.
 * @returns {number} - The hash code.
 */ export const hashCode = (str)=>{
    let hash = 0;
    for(let i = 0; i < str.length; i++){
        const character = str.charCodeAt(i);
        hash = (hash << 5) - hash + character;
        hash |= 0; // Convert to a 32-bit integer
    }
    return hash;
};
/**
 * Returns an interpolated color for a given input string.
 *
 * @param {string} input - The input string.
 * @param {string} colorStart - The starting color in hex format.
 * @param {string} colorEnd - The ending color in hex format.
 * @returns {string} - The interpolated color in HSL format.
 */ export const getColorForInput = (input, colorStart, colorEnd)=>{
    const maxLength = 20;
    const lengthFactor = input.length / maxLength;
    const hashFactor = hashCode(input) % 1000 / 1000; // hashFactor will be in [0,1]
    // Combine both factors.
    // This is a simple example of combining. There are other ways to combine the factors for different effects.
    const combinedFactor = (lengthFactor + hashFactor) / 2;
    return interpolateHSL(rgbToHSL(...hexToRGB(colorStart)), rgbToHSL(...hexToRGB(colorEnd)), combinedFactor);
};
/**
 * Determines the color to use for a specific bar chart based on certain labels.
 * The function contains hardcoded color values for specific strings found in the array of labels.
 *
 * @param {string} labels - Array of labels for which to determine the color.
 * @returns {string} - The color code for the given label.
 */ export const getBarChartColor = (labels)=>{
    const colorMap = {
        YUMMI: "#6EC1E4",
        WMT: "#4c2c8a",
        SHEN: "#a855c9",
        MELD: "#e81a44",
        LENFI: "#0187e9",
        LQ: "#21bba6",
        INDY: "#7a0fff",
        iUSD: "#7a0fff",
        NMKR: "#11f250",
        USDM: "#52b9ec",
        SNEK: "#00e1ff",
        FACT: "#66a29e",
        SUNDAE: primaryColor
    };
    for(const key in colorMap){
        const isLabelIncluded = (value)=>labels.some((label)=>label.includes(value));
        if (isLabelIncluded(key)) {
            return colorMap[key];
        }
    }
    const regex = /^[^-]+-[^-]+$/; // Test for pool name
    const labelForInput = labels.find((label)=>regex.test(label)) || "ADA-SUNDAE"; // Default to ADA-SUNDAE for color generation
    return getColorForInput(labelForInput, primaryColor, secondaryColor);
};
