export var EChartInterval;
(function(EChartInterval) {
    EChartInterval["1W"] = "1W";
    EChartInterval["2W"] = "2W";
    EChartInterval["1M"] = "1M";
    EChartInterval["MTD"] = "MTD";
    EChartInterval["3M"] = "3M";
    EChartInterval["YTD"] = "YTD";
    EChartInterval["ALL"] = "ALL";
})(EChartInterval || (EChartInterval = {}));
export var EChartScale;
(function(EChartScale) {
    EChartScale["linear"] = "linear";
    EChartScale["logarithmic"] = "logarithmic";
})(EChartScale || (EChartScale = {}));
