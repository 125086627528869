import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { getBrambleSdk } from "../client/bramble.sdk";
let liveFilledOrdersRef = 0;
let previousFilledOrdersRef = 0;
export const useProtocolStats = ()=>{
    const incrementFilledOrders = useCallback(()=>{
        liveFilledOrdersRef += 1;
    }, []);
    const query = useQuery({
        queryKey: [
            "protocolStats"
        ],
        queryFn: async ()=>{
            const { fetchProtocolStats } = await getBrambleSdk();
            const response = await fetchProtocolStats();
            return response?.stats;
        },
        refetchInterval: 1000 * 5,
        staleTime: 1000 * 60 * 1,
        gcTime: 1000 * 60 * 1
    });
    // Reset the live and new filled order values only when
    // we get new data from the last checkpoint.
    useEffect(()=>{
        const newFilledOrders = query.data?.filledOrders || 0;
        if (newFilledOrders > previousFilledOrdersRef) {
            liveFilledOrdersRef = 0;
            previousFilledOrdersRef = newFilledOrders;
        }
    }, [
        query.data?.filledOrders
    ]);
    return {
        ...query,
        liveFilledOrdersRef,
        incrementFilledOrders
    };
};
export const useProtocols = ()=>{
    return useQuery({
        queryKey: [
            "fetchProtocols"
        ],
        queryFn: async ()=>{
            const { fetchProtocols } = await getBrambleSdk();
            return fetchProtocols();
        },
        staleTime: 1000 * 60,
        gcTime: 1000 * 60
    });
};
