import { EOrderActions } from "./types";
const setAdaAfterSwap = (adaAfterSwap, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_ADA_AFTER_SWAP,
        payload: {
            adaAfterSwap
        }
    });
const setGivenAmount = (given, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_GIVEN_AMOUNT,
        payload: {
            given
        }
    });
const setGivenUtxoAmount = (givenUtxoAmount, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_GIVEN_UTXO_AMOUNT,
        payload: {
            givenUtxoAmount
        }
    });
const setTakenAmount = (taken, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_TAKEN_AMOUNT,
        payload: {
            taken
        }
    });
const setRatio = (ratio, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_RATIO,
        payload: {
            ratio
        }
    });
const setOrderRoute = (orderRoute, dispatch, key)=>{
    dispatch({
        key,
        type: EOrderActions.SET_ORDER_ROUTE,
        payload: {
            orderRoute
        }
    });
};
const unsetOrderRoute = (dispatch, key)=>dispatch({
        key,
        type: EOrderActions.UNSET_ORDER_ROUTE
    });
const setGivenExceedsBalance = (givenExceedsBalance, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_GIVEN_EXCEEDS_BALANCE,
        payload: {
            givenExceedsBalance
        }
    });
const setOrderFlowState = (flowState, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_ORDER_FLOW_STATE,
        payload: {
            flowState
        }
    });
const setOrderConsent = (orderConsent, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_ORDER_CONSENT,
        payload: {
            orderConsent
        }
    });
const swapOrderDirection = (dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SWAP_ORDER_DIRECTION
    });
const setFetchingPool = (fetchingPool, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_FETCHING_POOL,
        payload: {
            fetchingPool
        }
    });
const setTransaction = (transaction, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_TRANSACTION,
        payload: {
            transaction
        }
    });
const setTransactionState = (transactionState, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_TRANSACTION_STATE,
        payload: {
            transactionState
        }
    });
const resetOrderFlow = (dispatch, key)=>dispatch({
        key,
        type: EOrderActions.RESET_ORDER_FLOW
    });
const setOrderType = (orderType, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_ORDER_TYPE,
        payload: {
            orderType
        }
    });
const setContractVersion = (contractVersion, dispatch, key)=>dispatch({
        key,
        type: EOrderActions.SET_PROTOCOL_VERSION,
        payload: {
            contractVersion
        }
    });
export const OrderActions = {
    swapOrderDirection,
    resetOrderFlow,
    setAdaAfterSwap,
    setGivenAmount,
    setGivenUtxoAmount,
    setGivenExceedsBalance,
    setOrderConsent,
    setOrderFlowState,
    setOrderType,
    setRatio,
    setTakenAmount,
    setTransaction,
    setTransactionState,
    setFetchingPool,
    setContractVersion,
    setOrderRoute,
    unsetOrderRoute
};
