import { useEffect, useState } from "react";
export const useHeaderHeight = ()=>{
    const [headerHeight, setHeaderHeight] = useState(0);
    useEffect(()=>{
        const height = document.querySelector("main > header")?.getBoundingClientRect()?.height;
        if (height) setHeaderHeight(height);
    }, []);
    return {
        headerHeight
    };
};
