import { normalizeAssetIdWithDot } from "@sundaeswap/wallet-lite";
import { useQuery } from "@tanstack/react-query";
import request from "graphql-request";
import isEqual from "lodash/isEqual";
import { useCustomCompareMemo } from "use-custom-compare";
import useAppSearch from "../../hooks/router/useAppSearch";
import { useAssetsWithBalance } from "../../hooks/useAssetsWithBalance";
import { getAssetPriceToday, getAssetsMetadataWithCache, getAssetsPool, transformGQLAdaAsset } from "../../utils/assets.utils";
import { getBrambleSdk } from "../client/bramble.sdk";
import { FetchPoolByAssetsDocument } from "../generated/bramble.sdk";
import { BrambleTransformer } from "../utils/BrambleTransformer.class";
import { transformBrambleAsset, transformStatsAsset } from "../utils/transformers";
export const useAssetMetadata = (additionalIds)=>{
    const { given, taken } = useAppSearch();
    const { assetsWithBalance } = useAssetsWithBalance();
    const ids = useCustomCompareMemo(()=>{
        const map = new Map();
        const fullList = [
            ...[
                given,
                taken
            ].filter(Boolean),
            ...assetsWithBalance?.map(({ metadata: { assetId } })=>assetId) ?? [],
            ...additionalIds ?? []
        ];
        fullList.forEach((id)=>id && map.set(id, id));
        return [
            ...map.values()
        ].sort();
    }, [
        given,
        taken,
        assetsWithBalance,
        additionalIds
    ], isEqual);
    return useQuery({
        queryKey: [
            "assets",
            ...ids
        ],
        queryFn: async ()=>{
            const data = await getAssetsMetadataWithCache(ids);
            const { bramble, stats } = BrambleTransformer.transformBrambleAssetsFragment(// `data` is already transformed as Bramble, so we have to double transform for simplicity of types.
            [
                ...data.values()
            ].map((m)=>transformStatsAsset(m)));
            return {
                bramble,
                stats: stats.map((asset)=>{
                    return {
                        ...transformGQLAdaAsset(asset)
                    };
                })
            };
        },
        enabled: Boolean(ids?.length && ids.filter(Boolean)),
        staleTime: 1000 * (additionalIds ? 5 : 10 * 60),
        gcTime: 1000 * 60 * 25,
        refetchOnWindowFocus: false
    });
};
export const useAssetMetadataWithLivePrice = (additionalIds)=>{
    const { data } = useAssetMetadata(additionalIds);
    return useQuery({
        queryKey: [
            "assetPrices",
            data?.bramble.sort().join(",")
        ],
        queryFn: async ()=>{
            if (!data?.bramble) {
                return null;
            }
            const { GetAdaPriceDocument } = await import("../generated/stats2.sdk");
            const [poolsByAssets, { adaPrice }] = await Promise.all([
                request(window.__APP_CONFIG.apiUrls.bramble, FetchPoolByAssetsDocument, {
                    assets: data.bramble.map(({ id })=>id)
                }),
                request(window.__APP_CONFIG.apiUrls.stats2, GetAdaPriceDocument)
            ]);
            const assetsPool = getAssetsPool(data.bramble, poolsByAssets.pools.byAssets);
            const newMetadataMap = new Map();
            data.bramble.forEach((asset)=>{
                const pool = assetsPool[asset.id];
                const priceToday = getAssetPriceToday(adaPrice, pool);
                const metadata = {
                    ...transformBrambleAsset(asset),
                    priceToday
                };
                newMetadataMap.set(normalizeAssetIdWithDot(asset.id), metadata);
            });
            const { bramble, stats } = BrambleTransformer.transformBrambleAssetsFragment(// `data` is already transformed as Bramble, so we have to double transform for simplicity of types.
            [
                ...newMetadataMap.values()
            ].map((m)=>transformStatsAsset(m)));
            return {
                bramble: bramble,
                stats: stats.map((asset)=>{
                    return {
                        ...transformGQLAdaAsset(asset)
                    };
                })
            };
        },
        enabled: (data?.bramble.length || 0) > 0,
        // 10 minutes
        gcTime: 1000 * 60 * 10
    });
};
export const useAssetById = (assetId)=>{
    return useQuery({
        queryKey: [
            "assetDescription",
            assetId
        ],
        queryFn: async ()=>{
            if (!assetId) {
                return {
                    bramble: undefined,
                    stats: undefined
                };
            }
            const { fetchAssetById } = await getBrambleSdk();
            const response = await fetchAssetById({
                id: assetId
            });
            return BrambleTransformer.transformBrambleAssetFragment(response.assets.byId);
        },
        enabled: Boolean(assetId),
        staleTime: 1000 * 60 * 25,
        gcTime: 1000 * 60 * 25,
        refetchOnWindowFocus: false
    });
};
