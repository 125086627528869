import { EContractVersion } from "@sundaeswap/core";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { useCallback, useMemo } from "react";
import { useAppContext } from "../stores";
const useIsLPAsset = ()=>{
    const { state } = useAppContext();
    const protocols = useMemo(()=>state.protocols.map((p)=>({
                ...p,
                version: EContractVersion[p.version]
            })), [
        state.protocols
    ]);
    const isLPAssetV1 = useCallback((assetPolicyId)=>{
        return SundaeUtils.isLPAsset({
            assetPolicyId,
            version: EContractVersion.V1,
            protocols
        });
    }, [
        protocols
    ]);
    const isLPAssetV3 = useCallback((assetPolicyId)=>{
        return SundaeUtils.isLPAsset({
            assetPolicyId,
            version: EContractVersion.V3,
            protocols
        });
    }, [
        protocols
    ]);
    const isLPAsset = useCallback((assetPolicyId)=>{
        return isLPAssetV1(assetPolicyId) || isLPAssetV3(assetPolicyId);
    }, [
        isLPAssetV1,
        isLPAssetV3
    ]);
    return {
        isLPAsset,
        isLPAssetV1,
        isLPAssetV3
    };
};
export default useIsLPAsset;
