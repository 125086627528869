import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import Step7 from "./steps/Step7";
import Step1Title from "./titles/Step1Title";
export const ExplanationGuideSteps = {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7
};
export const ExplanationGuideTitles = {
    Step1Title
};
