import { EAppActions } from "./types";
/* -------------------------------------------------------------------------------------------------
 * App
 * -----------------------------------------------------------------------------------------------*/ const setErrorReporting = (errorReportingEnabled, dispatch)=>dispatch({
        type: EAppActions.SET_ERROR_REPORTING,
        payload: {
            errorReportingEnabled
        }
    });
const setOffCanvasOpen = (offCanvasOpen, dispatch)=>dispatch({
        type: EAppActions.SET_OFFCANVAS_OPEN,
        payload: {
            offCanvasOpen
        }
    });
const setLatestTxHash = (latestTxHash, dispatch)=>dispatch({
        type: EAppActions.SET_LATEST_TX_HASH,
        payload: {
            latestTxHash
        }
    });
const setActiveZapAsset = (activeZapAsset, dispatch)=>dispatch({
        type: EAppActions.SET_ACTIVE_ZAP_ASSET,
        payload: {
            activeZapAsset
        }
    });
const setActiveZapPool = (activeZapPool, dispatch)=>dispatch({
        type: EAppActions.SET_ACTIVE_ZAP_POOL,
        payload: {
            activeZapPool
        }
    });
const setPoolsSortingDirection = (sortPoolsSortingDirection, dispatch)=>dispatch({
        type: EAppActions.SET_POOLS_SORTING_DIRECTION,
        payload: {
            sortPoolsSortingDirection
        }
    });
const setSDK = (sdk, dispatch)=>dispatch({
        type: EAppActions.SET_SDK,
        payload: {
            sdk
        }
    });
const setV3MaxScooperFeeAmount = (V3MaxScooperFee, dispatch)=>dispatch({
        type: EAppActions.SET_V3_SCOOPER_FEE,
        payload: {
            V3MaxScooperFee
        }
    });
const setSortPoolsBy = (sortPoolsBy, dispatch)=>dispatch({
        type: EAppActions.SET_POOLS_SORT_BY,
        payload: {
            sortPoolsBy
        }
    });
const setTradeOnChartHoverParams = (tradeOnChartParams, dispatch)=>dispatch({
        type: EAppActions.SET_TRADE_ON_CHART_HOVER_PARAMS,
        payload: {
            tradeOnChartParams
        }
    });
const setTradeOnChartDialogParams = (tradeOnChartParams, dispatch)=>dispatch({
        type: EAppActions.SET_TRADE_ON_CHART_DIALOG_PARAMS,
        payload: {
            tradeOnChartParams
        }
    });
const setTasteTestActiveScrollIndex = (tasteTestActiveScrollIndex, dispatch)=>dispatch({
        type: EAppActions.SET_TASTE_TEST_ACTIVE_SCROLL_INDEX,
        payload: {
            tasteTestActiveScrollIndex
        }
    });
const setDisplayedOrderRouteIndex = (displayedOrderRouteIndex, dispatch)=>dispatch({
        type: EAppActions.SET_DISPLAYED_ORDER_ROUTE_INDEX,
        payload: {
            displayedOrderRouteIndex
        }
    });
/**
 * Set the state of a dialog.
 * @param {string} context - The optional `context` key, i.e. used for the asset selection.
 * @param {string} dialog - The dialog key. Nested dialogs can be accessed using dot notation.
 * @param {boolean} isOpen - Whether the dialog should be open or closed.
 * @param {Dispatch<IAppAction>} dispatch - The dispatch function.
 */ const setDialogState = (payload, dispatch)=>{
    return dispatch({
        type: EAppActions.SET_DIALOG_OPEN,
        payload
    });
};
/**
 * Set the state of a collapsible.
 * @param {string} collapsible - The collapsible key.
 * @param {boolean} isOpen - Whether the collapsible should be open or closed.
 * @param {Dispatch<IAppAction>} dispatch - The dispatch function.
 */ const setCollapsibleOpen = (payload, dispatch)=>{
    return dispatch({
        type: EAppActions.SET_COLLAPSIBLE_OPEN,
        payload
    });
};
/* -------------------------------------------------------------------------------------------------
 * Export
 * -----------------------------------------------------------------------------------------------*/ export const Actions = {
    setDisplayedOrderRouteIndex,
    setDialogState,
    setSDK,
    setErrorReporting,
    setOffCanvasOpen,
    setPoolsSortingDirection,
    setSortPoolsBy,
    setActiveZapAsset,
    setActiveZapPool,
    setLatestTxHash,
    setTasteTestActiveScrollIndex,
    setCollapsibleOpen,
    setV3MaxScooperFeeAmount,
    setTradeOnChartHoverParams,
    setTradeOnChartDialogParams
};
