import { AssetAmount } from "@sundaeswap/asset";
import { SundaeUtils } from "@sundaeswap/core/utilities";
import { ADA_METADATA } from "../constants/cardano.constants";
import { calculateAssetPriceInADA } from "./assets.utils";
/**
 * Calculates the total wallet balance in ADA.
 * @param {AssetAmount<IAssetMetaData[]>} assets - List of assets in the user's wallet.
 * @param {number} adaInUsd - Current ADA price in USD.
 * @returns {AssetAmount<IAssetMetaData>} - Total wallet balance in ADA as AssetAmount.
 */ export const calculateWalletBalanceInADA = (assets, adaInUsd)=>{
    if (!assets?.length || !adaInUsd) return new AssetAmount(0n, ADA_METADATA);
    const result = assets.reduce((value, item)=>{
        const { metadata } = item;
        const { priceToday } = metadata;
        // Since we already have the ada balance in `ada`, no need to calculate the value here
        if (SundaeUtils.isAdaAsset(metadata)) {
            return value;
        }
        const assetInAda = calculateAssetPriceInADA(Number(priceToday), adaInUsd, ADA_METADATA.decimals);
        const totalAssetInAda = AssetAmount.fromValue(item?.value?.multiply(assetInAda || 0) ?? 0n, ADA_METADATA.decimals);
        return value.add(totalAssetInAda);
    }, new AssetAmount(0n, ADA_METADATA));
    return result;
};
