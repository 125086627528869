export const joyrideIds = {
    components: {
        tooltip: {
            wrapper: "tooltip-wrapper"
        }
    },
    liquidity: "liquidity",
    portfolio: {
        balance: "portfolio-balance-guide",
        assets: "portfolio-assets-guide",
        liquidity: "portfolio-assets-liquidity",
        orders: "portfolio-assets-orders",
        yieldFarming: "portfolio-assets-yield-farming"
    },
    trade: {
        exchangeMode: "trade-exchange-mode-guide",
        orderType: "trade-order-type-guide"
    },
    tasteTest: {
        explanationGuide: "taste-test-explanation-guide",
        interactiveGuide: "taste-test-interactive-guide"
    },
    yieldFarming: "yield-farming",
    v3: {
        comparison: "v3-comparison-guide",
        createPool: "v3-create-pool-guide",
        migration: "v3-migration-guide"
    }
};
export const joyrideRoutes = {
    guides: {
        tasteTest: {
            interactiveGuide: {
                manageButton: "/taste-test/tt1"
            }
        }
    }
};
export const joyrideSelectors = {
    avatar: "control-center-avatar",
    poolBadges: {
        version: {
            v1: "pool-badge-version-v1",
            v3: "pool-badge-version-v3"
        }
    },
    guides: {
        v3: {
            migrationBanner: "v3-migration-banner"
        },
        liquidity: {
            actionsMenu: "liquidity-actions-menu",
            change24h: "liquidity-change-24h",
            communityDelegation: "yield-farming-table-community-delegation",
            createPoolButton: "liquidity-create-pool-button",
            filters: "liquidity-filters",
            fees24h: "liquidity-fees-24h",
            hra: "liquidity-hra",
            manageButton: "liquidity-manage-button",
            pool: "liquidity-pool",
            price: "liquidity-price",
            price24h: "liquidity-price-24h",
            tvl: "liquidity-tvl",
            volume24h: "liquidity-volume-24h",
            zapButton: "liquidity-zap-button"
        },
        yieldFarming: {
            stats: "yield-farming-stats",
            positions: "yield-farming-positions",
            leaderboard: "yield-farming-leaderboard"
        },
        portfolio: {
            assets: {
                priceChart: "portfolio-assets-price-chart",
                doughnutChart: "portfolio-assets-doughnut-chart",
                table: "portfolio-assets-table",
                actionsButton: "portfolio-assets-table-actions-button"
            },
            liquidity: {
                doughnutChart: "portfolio-liquidity-doughnut-chart",
                stackedBarChart: "portfolio-liquidity-stacked-bar-chart",
                table: "portfolio-liquidity-table",
                actionsButton: "portfolio-liquidity-table-actions-button"
            },
            yieldFarming: {
                breakdownChart: "portfolio-yield-farming-breakdown-chart",
                earningsChart: "portfolio-yield-farming-earnings-chart",
                container: "portfolio-yield-farming-delegation"
            },
            chart: "portfolio-chart",
            orderTable: "portfolio-order-table",
            balanceValues: {
                container: "portfolio-balance-values-container",
                overall: "portfolio-balance-values-overall",
                balance: "portfolio-balance-values-balance",
                assets: "portfolio-balance-values-assets",
                liquidity: "portfolio-balance-values-liquidity",
                fees: "portfolio-balance-values-earnings",
                yf: "portfolio-balance-values-yf"
            }
        },
        exchange: {
            assetInputs: "exchange-asset-inputs",
            assetSelectTrigger: {
                given: "exchange-asset-select-trigger-given",
                taken: "exchange-asset-select-trigger-taken"
            },
            exchangeWidget: "exchange-widget",
            assetMetadata: "exchange-asset-metadata",
            poolStats: "exchange-pool-stats",
            assetSelectDialog: "exchange-asset-select-dialog",
            tabs: "exchange-tabs",
            priceChart: "exchange-price-chart",
            transactionSettings: "exchange-transaction-settings",
            maxButton: "exchange-max-button",
            orderTypeSelect: "exchange-order-type-select",
            liquidityPoolSelect: "exchange-liquidity-pool-select",
            poolTable: "exchange-pool-table",
            openOrdersTable: "exchange-open-orders-table",
            orderHistoryTable: "exchange-order-history-table",
            scooperFee: "exchange-scooper-fee"
        },
        orders: {
            orderTypeSelect: "orders-order-type-select",
            cancelledSwitch: "orders-cancelled-switch",
            searchInput: "orders-search-input",
            exportTransactionReportButton: "orders-export-transaction-report-button",
            orderType: "orders-order-type",
            date: "orders-date",
            input: "orders-input",
            output: "orders-output",
            orderStatus: "orders-order-status",
            manage: "orders-manage"
        },
        tasteTest: {
            interactiveGuide: {
                detailBanner: "taste-test-detail-banner",
                totalRaised: "taste-test-amount-raised",
                contributedAmount: "taste-test-contributed-amount",
                status: "taste-test-status",
                price: "taste-test-price",
                priceChart: "taste-test-price-chart",
                tokenomics: "taste-test-tokenomics",
                transactionWidget: "taste-test-transaction-widget",
                projectDetails: "taste-test-project-details"
            }
        }
    }
};
