import { SundaeUtils } from "@sundaeswap/core/utilities";
import { usePoolsByPair } from "../gql/hooks/pools.query";
import { filterZeroQuantityPools } from "../utils/pool.utils";
export const useAvailablePools = ({ enabled, givenAssetId, takenAssetId })=>{
    const [coinA, coinB] = [
        givenAssetId,
        takenAssetId
    ].filter((id)=>typeof id === "string").sort((a, b)=>{
        if (SundaeUtils.isAdaAsset({
            assetId: a,
            decimals: 6
        })) return -1;
        if (SundaeUtils.isAdaAsset({
            assetId: b,
            decimals: 6
        })) return 1;
        return a.localeCompare(b);
    });
    const { data, ...rest } = usePoolsByPair({
        coinA,
        coinB,
        enabled
    });
    const availablePools = data.stats.sort((a, b)=>Number(b.quantityLP) - Number(a.quantityLP)).filter(filterZeroQuantityPools);
    return {
        availablePools,
        ...rest
    };
};
