import { useCallback, useMemo } from "react";
import { handleInputChange } from "../../../../components/OrderComponents/AssetInputs/utils";
import { SUNDAE_POOL_IDENT_V3 } from "../../../../constants/cardano.constants";
import { paths } from "../../../../pages/routes";
import { Actions, useAppContext } from "../../../../stores";
import { LocalControlCenterActions, useControlCenterContext } from "../../../../stores/ControlCenterContext";
import { OrderActions, useOrderContext } from "../../../../stores/OrderContext";
import useAppNavigate from "../../../router/useAppNavigate";
import { useAssetSelect } from "../../../useAssetSelect";
import { useAvailablePools } from "../../../useAvailablePools";
export const useV3ComparisonGuideCallbackhandler = ()=>{
    const navigate = useAppNavigate();
    const { handleAssetSelect } = useAssetSelect();
    const { state: { assets: { given, taken } }, dispatch } = useOrderContext();
    const { availablePools, isLoading } = useAvailablePools({
        givenAssetId: given?.metadata?.assetId,
        takenAssetId: taken?.metadata?.assetId
    });
    const { dispatch: appDispatch } = useAppContext();
    const { actionHandler, dispatchers: { localDispatch }, state: { localState: { guides: { activeGuideId } }, persistentState: { guides: { currentGuideId } } } } = useControlCenterContext();
    const sundaeV3Pool = useMemo(()=>availablePools.find((p)=>p.ident === SUNDAE_POOL_IDENT_V3), [
        availablePools,
        isLoading
    ]);
    const handleNavigateToLiquidity = useCallback((nextStepIndex)=>{
        if (!activeGuideId) {
            LocalControlCenterActions.guides.setActiveGuideId(currentGuideId ?? "", localDispatch);
            actionHandler.guides.goToStep(currentGuideId ?? "", nextStepIndex);
        }
        LocalControlCenterActions.guides.runGuide(false, localDispatch);
        navigate({
            to: paths.liquidity.root
        });
    }, [
        actionHandler,
        activeGuideId,
        currentGuideId,
        handleAssetSelect,
        localDispatch,
        navigate
    ]);
    const handleExchangeWidgetInputs = useCallback(()=>{
        handleInputChange(1, "given", given, dispatch);
        Actions.setCollapsibleOpen({
            collapsible: "orderFeeBreakdown",
            isOpen: true
        }, appDispatch);
    }, [
        appDispatch,
        dispatch,
        given
    ]);
    const v3ComparisonCallbackHandler = useCallback(({ action, index, status, type })=>{
        import("react-joyride").then(({ ACTIONS, EVENTS, STATUS })=>{
            if (action === ACTIONS.UPDATE && index <= 4 && sundaeV3Pool) {
                OrderActions.setOrderRoute([
                    sundaeV3Pool
                ], dispatch);
            }
            /* -------------------------------------------------------------------------------------------------
         * Callbacks when the tutorial is finished or skipped (user clicked on "Finish Guide" or "Skip" button)
         * -----------------------------------------------------------------------------------------------*/ if ([
                STATUS.FINISHED,
                STATUS.SKIPPED
            ].includes(status)) {
                actionHandler.guides.finish(currentGuideId ?? "");
                LocalControlCenterActions.guides.setActiveGuideId(undefined, localDispatch);
            } else if ([
                EVENTS.STEP_AFTER,
                EVENTS.TARGET_NOT_FOUND
            ].includes(type)) {
                /* -------------------------------------------------------------------------------------------------
           * When the user clicks the `Next` or `Back` button, we want to calculate the next step index first.
           * -----------------------------------------------------------------------------------------------*/ const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
                /* -------------------------------------------------------------------------------------------------
           * Before we handle the navigation, we need to check if the user wants to close the guide.
           * -----------------------------------------------------------------------------------------------*/ if (action === ACTIONS.CLOSE) {
                    LocalControlCenterActions.guides.showGuideCloseUI(true, localDispatch);
                }
                /* -------------------------------------------------------------------------------------------------
           * Then, we can handle the individual navigation based on the current step index and the action.
           * This allows us to handle custom navigation logic for specific steps.
           * -----------------------------------------------------------------------------------------------*/ if ([
                    ACTIONS.PREV,
                    ACTIONS.NEXT
                ].includes(action)) {
                    if (index === 5 && action === ACTIONS.NEXT) {
                        handleNavigateToLiquidity(nextStepIndex);
                    } else if (index === 1 && action === ACTIONS.PREV) {
                        if (!activeGuideId) {
                            LocalControlCenterActions.guides.setActiveGuideId(currentGuideId ?? "", localDispatch);
                            actionHandler.guides.goToStep(currentGuideId ?? "", nextStepIndex);
                        }
                        LocalControlCenterActions.guides.runGuide(false, localDispatch);
                        navigate({
                            to: paths.liquidity.root
                        });
                    } else if ([
                        2,
                        3,
                        4
                    ].includes(index) && action === ACTIONS.NEXT) {
                        handleExchangeWidgetInputs();
                        actionHandler.guides.goToStep(currentGuideId ?? "", nextStepIndex);
                    } else {
                        if (status !== STATUS.PAUSED) {
                            actionHandler.guides.goToStep(currentGuideId ?? "", nextStepIndex);
                        }
                    }
                }
            }
        });
    }, [
        actionHandler,
        activeGuideId,
        currentGuideId,
        localDispatch,
        navigate,
        sundaeV3Pool
    ]);
    return {
        v3ComparisonCallbackHandler
    };
};
