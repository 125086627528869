import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { joyrideIds, joyrideSelectors } from "../../../../../constants/joyride.constants";
import { PortfolioTab, paths } from "../../../../../pages/routes";
import { EGuideChapter } from "../../../../../stores/ControlCenterContext";
export const usePortfolioAssetsGuideJoyride = ()=>{
    const { t } = useI18N("joyride");
    return useMemo(()=>({
            rank: 2,
            description: t("portfolio.guides.assets.description"),
            title: t("portfolio.guides.assets.title"),
            chapter: EGuideChapter.PORTFOLIO,
            joyride: {
                id: joyrideIds.portfolio.assets,
                steps: [
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.assets
                        },
                        title: t("portfolio.guides.assets.steps.step1.title"),
                        content: t("portfolio.guides.assets.steps.step1.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.assets.doughnutChart}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.assets
                        },
                        title: t("portfolio.guides.assets.steps.step2.title"),
                        content: t("portfolio.guides.assets.steps.step2.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.assets.priceChart}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.assets
                        },
                        title: t("portfolio.guides.assets.steps.step3.title"),
                        content: t("portfolio.guides.assets.steps.step3.description"),
                        placement: "top",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.assets.table}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.assets
                        },
                        title: t("portfolio.guides.assets.steps.step4.title"),
                        content: t("portfolio.guides.assets.steps.step4.description"),
                        placement: "left",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.assets.actionsButton}"]`
                    }
                ]
            }
        }), [
        t
    ]);
};
