export var ELocalControlCenterStateActions;
(function(ELocalControlCenterStateActions) {
    ELocalControlCenterStateActions["LOAD_CHAPTERS"] = "LOAD_CHAPTERS";
    ELocalControlCenterStateActions["RUN_GUIDE"] = "RUN_GUIDE";
    ELocalControlCenterStateActions["SET_ACTIVE_CHAPTER"] = "SET_ACTIVE_CHAPTER";
    ELocalControlCenterStateActions["SET_ACTIVE_GUIDE"] = "SET_ACTIVE_GUIDE";
    ELocalControlCenterStateActions["SET_IS_NEXT_BUTTON_DISABLED"] = "SET_IS_NEXT_BUTTON_DISABLED";
    ELocalControlCenterStateActions["SET_LAST_CLOSED_GUIDE_ID"] = "SET_LAST_CLOSED_GUIDE_ID";
    ELocalControlCenterStateActions["SET_LAST_CLOSED_NEWS_ID"] = "SET_LAST_CLOSED_NEWS_ID";
    ELocalControlCenterStateActions["SET_WITH_ANIMATION_DELAY"] = "SET_WITH_ANIMATION_DELAY";
    ELocalControlCenterStateActions["SHOW_GUIDE_CLOSE_UI"] = "SHOW_GUIDE_CLOSE_UI";
    ELocalControlCenterStateActions["SHOW_GUIDES"] = "SHOW_GUIDES";
    ELocalControlCenterStateActions["SHOW_HELP_CENTER"] = "SHOW_HELP_CENTER";
    ELocalControlCenterStateActions["SHOW_INBOX"] = "SHOW_INBOX";
    ELocalControlCenterStateActions["SHOW_LOADING_ANIMATION_GUIDES"] = "SHOW_LOADING_ANIMATION_GUIDES";
    ELocalControlCenterStateActions["SHOW_SUCCESS_ANIMATION"] = "SHOW_SUCCESS_ANIMATION";
    ELocalControlCenterStateActions["SHOW_TOOLBAR"] = "SHOW_TOOLBAR";
})(ELocalControlCenterStateActions || (ELocalControlCenterStateActions = {}));
export var EPersistentControlCenterStateActions;
(function(EPersistentControlCenterStateActions) {
    EPersistentControlCenterStateActions["CLOSE_GUIDE"] = "CLOSE_GUIDE";
    EPersistentControlCenterStateActions["COMPLETE_GUIDE"] = "COMPLETE_GUIDE";
    EPersistentControlCenterStateActions["HANDLE_STEP"] = "HANDLE_STEP";
    EPersistentControlCenterStateActions["OPEN_GUIDES_ON_MOUNT"] = "OPEN_GUIDES_ON_MOUNT";
    EPersistentControlCenterStateActions["SET_IS_EVERY_CHAPTER_COMPLETED"] = "SET_IS_EVERY_CHAPTER_COMPLETED";
    EPersistentControlCenterStateActions["START_GUIDE"] = "START_GUIDE";
})(EPersistentControlCenterStateActions || (EPersistentControlCenterStateActions = {}));
export var EGuideChapter;
(function(EGuideChapter) {
    EGuideChapter["EXCHANGE"] = "2";
    EGuideChapter["LIQUIDITY"] = "3";
    EGuideChapter["PORTFOLIO"] = "1";
    EGuideChapter["TASTE_TEST"] = "4";
    EGuideChapter["YIELD_FARMING"] = "5";
    EGuideChapter["V3"] = "0";
})(EGuideChapter || (EGuideChapter = {}));
