import gql from "graphql-tag";
export const EarningFragmentFragmentDoc = gql`
  fragment EarningFragment on Earning {
    byPool {
      pool {
        poolIdent
        assetA
        assetB
        lpAsset
      }
      value {
        assetID
        amount
      }
    }
    date {
      format(layout: "2006-01-02")
    }
    expiration {
      format(layout: "2006-01-02T15:04:05Z")
    }
    owner
    ownerID
    program {
      label
      id
    }
    status
    txExpiration {
      format(layout: "2006-01-02T15:04:05Z")
    }
    txId
    txRollbackHorizon {
      format(layout: "2006-01-02T15:04:05Z")
    }
    value {
      assetID
      amount
    }
    verified
  }
`;
export const GetProgramsDocument = gql`
  query getPrograms {
    programs {
      id
      label
      dailyEmission {
        assetID
        amount
      }
      firstDailyRewards {
        format
        slot
      }
      lastDailyRewards {
        format
        slot
      }
      emittedAsset
      stakedAsset
      eligiblePools {
        poolIdent
        lpAsset
        assetA
        assetB
      }
      disqualifiedPools {
        poolIdent
        lpAsset
        assetA
        assetB
      }
      minLPIntegerPercent
      maxPoolCount
      maxPoolIntegerPercent
      serviceFee
    }
  }
`;
export const GetPositionsDocument = gql`
  query getPositions($beneficiary: [String!]!) {
    positions(beneficiary: $beneficiary) {
      spentTxHash
      txHash
      index
      createdAt {
        format
        slot
      }
      spentTxHash
      spentAt {
        format
        slot
      }
      ownerID
      value {
        assetID
        amount
      }
      delegation {
        pool {
          poolIdent
          lpAsset
          assetA
          assetB
        }
        program {
          id
          label
          dailyEmission {
            assetID
            amount
          }
          firstDailyRewards {
            format
            slot
          }
          lastDailyRewards {
            format
            slot
          }
          emittedAsset
          stakedAsset
          eligiblePools {
            poolIdent
            lpAsset
            assetA
            assetB
          }
          disqualifiedPools {
            poolIdent
            lpAsset
            assetA
            assetB
          }
          minLPIntegerPercent
          maxPoolCount
          maxPoolIntegerPercent
        }
        weight
      }
    }
  }
`;
export const GetEarningsDocument = gql`
  query getEarnings($beneficiary: [String!]!) {
    earnings(beneficiary: $beneficiary) {
      ...EarningFragment
    }
  }
  ${EarningFragmentFragmentDoc}
`;
export const GetEstimatedEarningsDocument = gql`
  query getEstimatedEarnings($beneficiary: [String!]!) {
    estimatedEarnings(beneficiary: $beneficiary) {
      ...EarningFragment
    }
  }
  ${EarningFragmentFragmentDoc}
`;
export const GetCalculationDocument = gql`
  query getCalculation($program: String!, $date: String!) {
    calculation(program: $program, date: $date) {
      program {
        firstDailyRewards {
          slot
        }
        lastDailyRewards {
          slot
        }
        id
        label
        eligiblePools {
          poolIdent
        }
        disqualifiedPools {
          poolIdent
        }
        emittedAsset
        stakedAsset
      }
      perPoolHRA {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      emissionsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      estimatedLockedLovelaceByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      delegationsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
    }
  }
`;
export const GetEstimationDocument = gql`
  query getEstimation($program: String!, $timestamp: String) {
    estimation(program: $program, timestamp: $timestamp) {
      program {
        firstDailyRewards {
          slot
        }
        lastDailyRewards {
          slot
        }
        id
        label
        eligiblePools {
          poolIdent
        }
        disqualifiedPools {
          poolIdent
        }
        emittedAsset
        stakedAsset
      }
      cumulativeDelegationsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      perPoolHRA {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      emissionsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      estimatedLockedLovelaceByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
      delegationsByPool {
        pool {
          poolIdent
        }
        quantity
        percent
      }
    }
  }
`;
export const ClaimRewardsDocument = gql`
  mutation claimRewards(
    $userInputs: [TxIn!]!
    $destinationAddress: String!
    $earnings: [EarningInput!]!
  ) {
    claim(
      userInputs: $userInputs
      destinationAddress: $destinationAddress
      earnings: $earnings
    ) {
      transactionHex
      witnesses {
        pubKey
        signature
      }
    }
  }
`;
export const GetRollupDocument = gql`
  query getRollup($key: String!) {
    rollup(key: $key)
  }
`;
const defaultWrapper = (action, _operationName, _operationType)=>action();
export function getSdk(client, withWrapper = defaultWrapper) {
    return {
        getPrograms (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetProgramsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPrograms", "query");
        },
        getPositions (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPositionsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPositions", "query");
        },
        getEarnings (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetEarningsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getEarnings", "query");
        },
        getEstimatedEarnings (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetEstimatedEarningsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getEstimatedEarnings", "query");
        },
        getCalculation (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetCalculationDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getCalculation", "query");
        },
        getEstimation (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetEstimationDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getEstimation", "query");
        },
        claimRewards (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(ClaimRewardsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "claimRewards", "mutation");
        },
        getRollup (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetRollupDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getRollup", "query");
        }
    };
}
