import { useEffect } from "react";
import useAppSearch from "../../../hooks/router/useAppSearch";
import { PortfolioTab } from "../../../pages/routes";
import { PortfolioActions } from "../actions";
import { usePortfolioContext } from "../context";
export const useActiveTabTitle = ()=>{
    const { dispatch, state } = usePortfolioContext();
    const search = useAppSearch();
    /// Set initial tab title.
    useEffect(()=>{
        const activeTabTitle = search.tab || PortfolioTab.walletOverview;
        PortfolioActions.derived.setActiveTabTitle(activeTabTitle, dispatch);
    }, [
        state.derived?.activeTabTitle,
        dispatch,
        search.tab
    ]);
};
