import { EPortfolioActions } from "./types";
/* -------------------------------------------------------------------------------------------------
 * DERIVED
 * -----------------------------------------------------------------------------------------------*/ const setActiveTabTitle = (activeTabTitle, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ACTIVE_TAB_TITLE,
        payload: {
            activeTabTitle
        }
    });
};
const setIsSidebarCollapsed = (isSidebarCollapsed, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_SIDEBAR_COLLAPSED,
        payload: {
            isSidebarCollapsed
        }
    });
};
const setPortfolioBalanceValues = (portfolioBalanceValues, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_PORTFOLIO_BALANCE_VALUES,
        payload: {
            portfolioBalanceValues
        }
    });
};
const setTotalPortfolioBalanceInAda = (totalPortfolioBalanceInAda, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA,
        payload: {
            totalPortfolioBalanceInAda
        }
    });
};
const setIsLoadingPortfolioBalanceValues = (isLoadingPortfolioBalanceValues, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES,
        payload: {
            isLoadingPortfolioBalanceValues
        }
    });
};
/* -------------------------------------------------------------------------------------------------
 * ASSETS
 * -----------------------------------------------------------------------------------------------*/ const setSelectedAsset = (selectedAsset, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_SELECTED_ASSET,
        payload: {
            selectedAsset
        }
    });
};
/* -------------------------------------------------------------------------------------------------
 * FARMS
 * -----------------------------------------------------------------------------------------------*/ const setIsFetchedFarms = (isFetchedFarms, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_FETCHED_FARMS,
        payload: {
            isFetchedFarms
        }
    });
};
const setIsFetchingFarms = (isFetchingFarms, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_FETCHING_FARMS,
        payload: {
            isFetchingFarms
        }
    });
};
const setFarmPositions = (positions, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_FARM_POSITIONS,
        payload: {
            positions
        }
    });
};
const setFarmsHistory = (history, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_FARMS_HISTORY,
        payload: {
            history
        }
    });
};
/* -------------------------------------------------------------------------------------------------
 * OPS
 * -----------------------------------------------------------------------------------------------*/ const setAllOrders = (allOrders, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ALL_ORDERS,
        payload: {
            allOrders
        }
    });
};
const setFormattedOrders = (formattedOrders, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_FORMATTED_ORDERS,
        payload: {
            formattedOrders
        }
    });
};
const setOpsOpen = (opsOpen, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_OPS_OPEN,
        payload: {
            opsOpen
        }
    });
};
const setOpsHistory = (opsHistory, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_OPS_HISTORY,
        payload: {
            opsHistory
        }
    });
};
const setIsFetchedOps = (isFetchedOps, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_FETCHED_OPS,
        payload: {
            isFetchedOps
        }
    });
};
const setIsFetchingOps = (isFetchingOps, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_FETCHING_OPS,
        payload: {
            isFetchingOps
        }
    });
};
const setIsRefetchingOps = (isRefetchingOps, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_IS_REFETCHING_OPS,
        payload: {
            isRefetchingOps
        }
    });
};
const setOrdersSearchValue = (searchValue, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ORDERS_SEARCH_VALUE,
        payload: {
            searchValue
        }
    });
};
const setOrderStatusFilterItems = (items, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ORDER_STATUS_FILTER_ITEMS,
        payload: {
            items
        }
    });
};
const setOrderStatusFilterValue = (value, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ORDER_STATUS_FILTER_VALUE,
        payload: {
            value
        }
    });
};
const setOrdersTableColumnWidths = (value, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ORDERS_TABLE_COLUMN_WIDTHS,
        payload: {
            value
        }
    });
};
const setOrdersTableHeaders = (value, dispatch, key)=>{
    dispatch({
        key,
        type: EPortfolioActions.SET_ORDERS_TABLE_HEADERS,
        payload: {
            value
        }
    });
};
export const PortfolioActions = {
    derived: {
        setActiveTabTitle,
        setIsSidebarCollapsed,
        setPortfolioBalanceValues,
        setTotalPortfolioBalanceInAda,
        setIsLoadingPortfolioBalanceValues
    },
    assets: {
        setSelectedAsset
    },
    farms: {
        setIsFetchedFarms,
        setIsFetchingFarms,
        setFarmPositions,
        setFarmsHistory
    },
    ops: {
        setAllOrders,
        setFormattedOrders,
        setOpsOpen,
        setOpsHistory,
        setIsFetchedOps,
        setIsFetchingOps,
        setIsRefetchingOps,
        setOrdersSearchValue,
        setOrderStatusFilterItems,
        setOrderStatusFilterValue,
        setOrdersTableColumnWidths,
        setOrdersTableHeaders
    }
};
