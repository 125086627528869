export var EFarmsViewMode;
(function(EFarmsViewMode) {
    EFarmsViewMode["list"] = "list";
    EFarmsViewMode["tiles"] = "tiles";
})(EFarmsViewMode || (EFarmsViewMode = {}));
export var EFarmsFilter;
(function(EFarmsFilter) {
    EFarmsFilter["all"] = "all";
    EFarmsFilter["user"] = "user";
    EFarmsFilter["core"] = "core";
    EFarmsFilter["community"] = "community";
})(EFarmsFilter || (EFarmsFilter = {}));
export var ECardanoExplorer;
(function(ECardanoExplorer) {
    ECardanoExplorer["cardanoExplorer"] = "cardanoExplorer";
    ECardanoExplorer["cardanoscan"] = "cardanoscan";
    ECardanoExplorer["cexplorer"] = "cexplorer";
})(ECardanoExplorer || (ECardanoExplorer = {}));
export var ECardanoExplorerType;
(function(ECardanoExplorerType) {
    ECardanoExplorerType["policy"] = "tokenPolicy";
    ECardanoExplorerType["transaction"] = "transaction";
    ECardanoExplorerType["asset"] = "asset";
})(ECardanoExplorerType || (ECardanoExplorerType = {}));
export var EProfileAppActions;
(function(EProfileAppActions) {
    /* -------------------------------------------------------------------------------------------------
   * App
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["LOGOUT"] = "LOGOUT";
    EProfileAppActions["SET_ERROR_REPORTING"] = "SET_ERROR_REPORTING";
    EProfileAppActions["SET_HOTJAR_ENABLED"] = "SET_HOTJAR_ENABLED";
    EProfileAppActions["SET_ACTIVE_LANGUAGE"] = "SET_ACTIVE_LANGUAGE";
    EProfileAppActions["SET_CARDANO_EXPLORER"] = "SET_CARDANO_EXPLORER";
    EProfileAppActions["SET_PRIVACY_MODE"] = "SET_PRIVACY_MODE";
    EProfileAppActions["SET_PROFILE_ENABLED"] = "SET_PROFILE_ENABLED";
    EProfileAppActions["SYNC_DEFAULT_STATE"] = "SYNC_DEFAULT_STATE";
    EProfileAppActions["SET_PREFERS_REDUCED_MOTION"] = "SET_PREFERS_REDUCED_MOTION";
    /* -------------------------------------------------------------------------------------------------
   * TX Settings
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["SET_FEES_VIEW_MODE"] = "SET_FEES_VIEW_MODE";
    EProfileAppActions["SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO"] = "SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO";
    EProfileAppActions["SET_SLIPPAGE_TOLERANCE"] = "SET_SLIPPAGE_TOLERANCE";
    /* -------------------------------------------------------------------------------------------------
   * Limit Orders (temporarily used to track tx hashes in order to properly flag limit orders on the orders page - will be provided by the API in the future)
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["ADD_LIMIT_ORDER"] = "ADD_LIMIT_ORDER";
    /* -------------------------------------------------------------------------------------------------
   * Farms
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["SET_FARMS_FILTER"] = "SET_FARMS_FILTER";
    EProfileAppActions["SET_FARMS_VIEW_MODE"] = "SET_FARMS_VIEW_MODE";
    /* -------------------------------------------------------------------------------------------------
   * Pool Filters
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["SET_POOL_FILTERS"] = "SET_POOL_FILTERS";
    EProfileAppActions["SET_FAVORITE_POOL"] = "SET_FAVORITE_POOL";
    EProfileAppActions["UNSET_FAVORITE_POOL"] = "UNSET_FAVORITE_POOL";
    /* -------------------------------------------------------------------------------------------------
   * Pool Toggles
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["SET_TOGGLES"] = "SET_TOGGLES";
    /* -------------------------------------------------------------------------------------------------
   * Personalization
   * -----------------------------------------------------------------------------------------------*/ EProfileAppActions["SET_PREFERRED_HANDLE"] = "SET_PREFERRED_HANDLE";
    EProfileAppActions["SET_PREFERRED_AVATAR"] = "SET_PREFERRED_AVATAR";
})(EProfileAppActions || (EProfileAppActions = {}));
