import { useQuery } from "@tanstack/react-query";
import { fetchPrismicNewsByEveryTags } from "../../prismic/prismic.config";
export const usePrismicNews = ({ tags })=>useQuery({
        queryKey: [
            "news",
            ...tags
        ],
        queryFn: ()=>fetchPrismicNewsByEveryTags(tags),
        refetchOnWindowFocus: false,
        gcTime: 1000 * 60 * 10,
        refetchInterval: 1000 * 60 * 10,
        staleTime: 1000 * 60 * 5
    });
