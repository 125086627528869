import { useWalletObserver } from "@sundaeswap/wallet-lite";
import { useQuery } from "@tanstack/react-query";
import { getBrambleSdk } from "../client/bramble.sdk";
import { getStats2Sdk } from "../client/statsSdk";
import { BrambleTransformer } from "../utils/BrambleTransformer.class";
export const useWalletPositions = ({ pageSize = 50, page = 0 })=>{
    const { mainAddress } = useWalletObserver();
    const queryInfo = useQuery({
        queryKey: [
            "getWalletPositions",
            mainAddress,
            pageSize,
            page
        ],
        queryFn: async ()=>{
            if (!mainAddress) {
                return {
                    bramble: [],
                    stats: []
                };
            }
            const [{ getAdaPrice }, { fetchPositions }] = await Promise.all([
                getStats2Sdk(),
                getBrambleSdk()
            ]);
            const adaPrice = (await getAdaPrice()).adaPrice;
            const response = await fetchPositions({
                address: mainAddress
            });
            return BrambleTransformer.transformBrambleLiquidity(response?.portfolio?.liquidity ?? [], adaPrice);
        },
        enabled: !!mainAddress,
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });
    return {
        isFetched: !!mainAddress && queryInfo.isFetched,
        isFetching: !!mainAddress && queryInfo.isFetching,
        isLoading: !!mainAddress && queryInfo.isLoading,
        positions: queryInfo.data ?? {
            bramble: [],
            stats: []
        }
    };
};
