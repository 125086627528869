export var EAppActions;
(function(EAppActions) {
    /* -------------------------------------------------------------------------------------------------
   * App
   * -----------------------------------------------------------------------------------------------*/ EAppActions["SET_DISPLAYED_ORDER_ROUTE_INDEX"] = "SET_DISPLAYED_ORDER_ROUTE_INDEX";
    EAppActions["SET_SDK"] = "SET_SDK";
    EAppActions["SET_CONNECTED_WALLET"] = "SET_CONNECTED_WALLET";
    EAppActions["SET_ERROR_REPORTING"] = "SET_ERROR_REPORTING";
    EAppActions["SET_OFFCANVAS_OPEN"] = "SET_OFFCANVAS_OPEN";
    EAppActions["SET_POOLS_SORTING_DIRECTION"] = "SET_POOLS_SORTING_DIRECTION";
    EAppActions["SET_POOLS_SORT_BY"] = "SET_POOLS_SORT_BY";
    EAppActions["SET_ACTIVE_ZAP_ASSET"] = "SET_ACTIVE_ZAP_ASSET";
    EAppActions["SET_ACTIVE_ZAP_POOL"] = "SET_ACTIVE_ZAP_POOL";
    EAppActions["SET_EXCHANGE_WIDGET_NAVIGATION_FROM"] = "SET_EXCHANGE_WIDGET_NAVIGATION_FROM";
    EAppActions["SET_LATEST_TX_HASH"] = "SET_LATEST_TX_HASH";
    EAppActions["SET_DIALOG_OPEN"] = "SET_DIALOG_OPEN";
    EAppActions["SET_COLLAPSIBLE_OPEN"] = "SET_COLLAPSIBLE_OPEN";
    EAppActions["SET_TASTE_TEST_ACTIVE_SCROLL_INDEX"] = "SET_TASTE_TEST_ACTIVE_SCROLL_INDEX";
    EAppActions["SET_V3_SCOOPER_FEE"] = "SET_V3_SCOOPER_FEE";
    EAppActions["SET_TRADE_ON_CHART_HOVER_PARAMS"] = "SET_TRADE_ON_CHART_HOVER_PARAMS";
    EAppActions["SET_TRADE_ON_CHART_DIALOG_PARAMS"] = "SET_TRADE_ON_CHART_DIALOG_PARAMS";
})(EAppActions || (EAppActions = {}));
export var ETasteTestScrollIndex;
(function(ETasteTestScrollIndex) {
    ETasteTestScrollIndex[ETasteTestScrollIndex["JUMBOTRON"] = 0] = "JUMBOTRON";
    ETasteTestScrollIndex[ETasteTestScrollIndex["BENEFITS"] = 1] = "BENEFITS";
    ETasteTestScrollIndex[ETasteTestScrollIndex["UPCOMING"] = 2] = "UPCOMING";
    ETasteTestScrollIndex[ETasteTestScrollIndex["ALL"] = 3] = "ALL";
    ETasteTestScrollIndex[ETasteTestScrollIndex["FAQ"] = 4] = "FAQ";
})(ETasteTestScrollIndex || (ETasteTestScrollIndex = {}));
export var EPoolTableSortingOptions;
(function(EPoolTableSortingOptions) {
    EPoolTableSortingOptions["apy"] = "apy";
    EPoolTableSortingOptions["change24"] = "change24";
    EPoolTableSortingOptions["fees24"] = "fees24";
    EPoolTableSortingOptions["price"] = "price";
    EPoolTableSortingOptions["totalValueLocked"] = "totalValueLocked";
    EPoolTableSortingOptions["volume24"] = "volume24";
    EPoolTableSortingOptions["poolName"] = "poolName";
})(EPoolTableSortingOptions || (EPoolTableSortingOptions = {}));
export var ESortingDirection;
(function(ESortingDirection) {
    ESortingDirection["asc"] = "asc";
    ESortingDirection["desc"] = "desc";
})(ESortingDirection || (ESortingDirection = {}));
