import { Liquidity } from "./liquidity";
import { Portfolio } from "./portfolio";
import { TasteTest } from "./taste-test";
import { Trade } from "./trade";
import { V3 } from "./v3";
export const Guides = {
    Liquidity,
    Portfolio,
    TasteTest,
    Trade,
    V3
};
