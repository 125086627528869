import isEqual from "lodash/isEqual";
import { useRef } from "react";
/**
 * Creates a memoized version of a value based on deep comparison.
 * Useful for dependencies that are objects or arrays in `useMemo` or `useEffect`.
 * @param value - The value to be memoized.
 * @returns A memoized version of the input value.
 */ export function useDeepCompareMemoize(value) {
    const ref = useRef();
    if (!isEqual(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}
