import { AssetAmount } from "@sundaeswap/asset";
import { ADA_METADATA } from "@sundaeswap/core";
import { colors } from "@sundaeswap/tailwind-config";
import { isWithinInterval } from "date-fns/isWithinInterval";
import { startOfMonth } from "date-fns/startOfMonth";
import { startOfYear } from "date-fns/startOfYear";
import { subDays } from "date-fns/subDays";
import { subMonths } from "date-fns/subMonths";
import { subWeeks } from "date-fns/subWeeks";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { darken, lighten, transparentize } from "polished";
import { DEFAULT_DECIMALS } from "../../../../constants/cardano.constants";
import { EChartInterval } from "../../../../types/charts";
import { calculateAssetPriceInADA } from "../../../../utils/assets.utils";
import { getBarChartColor } from "../../../../utils/color.utils";
import { stringToBigint } from "../../../../utils/number-format";
import { getPoolName } from "../../../../utils/pool.utils";
import { truncateString } from "../../../../utils/string-format";
const primaryColor = colors.primary["DEFAULT"];
const secondaryColor = colors.secondary["DEFAULT"];
const defaultBarChartOptions = {
    borderRadius: 2,
    borderWidth: 2,
    maxBarThickness: 75,
    type: "bar"
};
/**
 * Sorts an array of TEarningWithAdaAmount objects by their date in ascending order.
 *
 * @param {TEarningWithAdaAmount[]} earnings - Array of earnings to be sorted.
 * @returns {TEarningWithAdaAmount[]} Sorted array of earnings.
 */ export const sortEarningsByDate = (earnings)=>earnings.sort((a, b)=>new Date(a.date.format).getTime() - new Date(b.date.format).getTime());
/**
 * Groups an array of TEarningWithAdaAmount objects by their date and program ID.
 *
 * @param {TEarningWithAdaAmount[]} earnings - The array of earnings to be grouped.
 * @param {string} locale - The locale string for date formatting.
 * @returns {IGroupedEarnings} An object representing the grouped earnings by date and program ID.
 */ export const groupEarningsByDateAndProgram = (earnings, locale)=>{
    return earnings.reduce((acc, item)=>{
        const date = new Intl.DateTimeFormat(locale, {
            month: "short",
            day: "numeric",
            year: "2-digit"
        }).format(new Date(item.date.format));
        const program = item.program.id;
        if (!acc[date]) acc[date] = {};
        if (!acc[date][program]) acc[date][program] = [];
        acc[date][program].push(item);
        return acc;
    }, {});
};
/**
 * Updates the datasets array based on the information from a single byPool entry.
 * Creates a new dataset if the combination of pool and program ID doesn't exist yet.
 *
 * @param {TPerPoolValueWithAdaAmount} byPoolEntry - The pool entry to be processed.
 * @param {string[]} labels - The array of labels representing each bar's corresponding date.
 * @param {ChartDataset[]} datasets - The array of datasets where the pool data should be added or updated.
 * @param {string} programId - The program ID corresponding to this pool data.
 */ export const handlePoolData = (byPoolEntry, labels, datasets, programId, isDark)=>{
    const isEmptyEntry = isEmpty(byPoolEntry.pool) && isEmpty(byPoolEntry.value);
    if (!isEmptyEntry) {
        const amount = Number(byPoolEntry.value[0]?.amountInAda ?? "0");
        if (amount !== 0) {
            const poolName = getPoolName({
                assetA: byPoolEntry.pool?.assetA,
                assetB: byPoolEntry.pool?.assetB
            });
            const poolIdent = byPoolEntry.pool?.ident ?? "";
            const poolLabel = `${poolName} (${truncateString(poolIdent, 2, 2)} - ${programId})`;
            let dataset = datasets.find((d)=>d.label === poolLabel && d.stack === programId);
            if (!dataset) {
                dataset = {
                    ...defaultBarChartOptions,
                    label: poolLabel,
                    stack: programId,
                    data: new Array(labels.length).fill(0),
                    borderColor: lighten(0.1, getBarChartColor([
                        poolLabel,
                        programId
                    ])),
                    backgroundColor: transparentize(isDark ? 0.8 : 0.7, lighten(0.1, getBarChartColor([
                        poolLabel,
                        programId
                    ]))),
                    hoverBackgroundColor: transparentize(isDark ? 0.2 : 0.4, getBarChartColor([
                        poolLabel,
                        programId
                    ])),
                    yAxisID: "yBar"
                };
                datasets.push(dataset);
            }
            dataset.data[labels.length - 1] = amount;
        }
    }
};
/**
 * Handles the processing and manipulation of unverified data (estimations) in the context of ChartDataset.
 * It takes a pool entry, and if it has a non-zero amount, it updates or creates a dataset with that information.
 *
 * @param {TPerPoolValueWithAdaAmount} byPoolEntry - The pool entry containing value and other information.
 * @param {string[]} labels - The labels for the chart.
 * @param {ChartDataset[]} datasets - The existing datasets.
 * @param {string} programId - The ID of the program.
 * @param {string} datasetLabel - The label for the new dataset.
 */ export const handleUnverifiedData = (byPoolEntry, labels, datasets, programId, datasetLabel, isDark)=>{
    const amount = Number(byPoolEntry.value[0]?.amountInAda ?? "0");
    if (amount !== 0) {
        const poolName = getPoolName({
            assetA: byPoolEntry.pool?.assetA,
            assetB: byPoolEntry.pool?.assetB
        });
        const poolIdent = byPoolEntry.pool?.ident ?? "";
        const poolLabel = `${poolName} (${truncateString(poolIdent, 2, 2)} - ${programId})`;
        let dataset = datasets.find((d)=>d.label === poolLabel && d.stack === "estimated");
        if (!dataset) {
            dataset = {
                ...defaultBarChartOptions,
                label: `${datasetLabel} ${poolLabel}`,
                stack: programId,
                xAxisID: "x",
                data: new Array(labels.length).fill(0),
                borderSkipped: true,
                backgroundColor: transparentize(isDark ? 0.8 : 0.7, lighten(0.3, getBarChartColor([
                    poolLabel,
                    programId
                ]))),
                hoverBackgroundColor: transparentize(isDark ? 0.2 : 0.4, getBarChartColor([
                    poolLabel,
                    programId
                ])),
                yAxisID: "yBar"
            };
            datasets.push(dataset);
        }
        dataset.data[labels.length - 1] = amount;
    }
};
/**
 * Handles the processing of non-specific data (no `byPool` entries; every entry before Aug 15, 23) for a given dataset and updates it accordingly.
 * If the totalValue is non-zero, the function either creates a new dataset or updates the existing one.
 *
 * @param {number} value - The value to be added or updated in the dataset.
 * @param {string[]} labels - The array of labels for the dataset.
 * @param {ChartDataset[]} datasets - The existing datasets that might be updated.
 * @param {string} programId - The program ID to which the dataset is related.
 * @param {string} datasetLabel - The label for the dataset to be created or updated.
 */ export const handleUnspecificData = (value, labels, datasets, programId, datasetLabel, isDark)=>{
    const totalValue = Number(value ?? "0");
    let dataset = datasets.find((d)=>d.label === datasetLabel && d.stack === programId);
    if (!dataset) {
        dataset = {
            ...defaultBarChartOptions,
            label: datasetLabel,
            stack: programId,
            data: new Array(labels.length).fill(0),
            borderColor: getBarChartColor([
                datasetLabel,
                programId
            ]),
            backgroundColor: transparentize(isDark ? 0.8 : 0.7, darken(0.1, getBarChartColor([
                datasetLabel,
                programId
            ]))),
            hoverBackgroundColor: transparentize(isDark ? 0.2 : 0.4, getBarChartColor([
                datasetLabel,
                programId
            ])),
            yAxisID: "yBar"
        };
        datasets.push(dataset);
    }
    dataset.data[labels.length - 1] = totalValue;
};
/**
 * Takes an array of numerical data and replaces each element with the cumulative total up to that point.
 * This function modifies the original array and also returns the modified array.
 *
 * @param {number[]} lineData - The array of numerical data to be transformed into cumulative data.
 * @returns {number[]} - The modified array with cumulative totals.
 */ export const handleCumulativeData = (lineData)=>{
    let cumulativeTotal = 0;
    lineData.forEach((val, idx)=>{
        cumulativeTotal += val;
        lineData[idx] = cumulativeTotal;
    });
    return lineData;
};
/**
 * Processes a single earning entry to populate chart datasets and labels.
 * The function also calculates the total amount in ADA for the entry and adds it to the datasets.
 * Depending on the earning status (Verified or Unverified) and its `byPool` length, the function delegates to specific handlers (`handleUnverifiedData`, `handlePoolData` or `handleUnspecificData`).
 *
 * @param {TEarningWithAdaAmount} entry - The earning entry to be processed.
 * @param {string[]} labels - The existing chart labels.
 * @param {ChartDataset[]} datasets - The existing chart datasets.
 * @param {string} programId - The program ID for the earning entry.
 * @param {object} datasetLabels - The dataset labels configuration object.
 * @param {string} datasetLabels.totalPerDay - Label for the total per day dataset.
 * @param {string} datasetLabels.totalOverTime - Label for the total over time dataset.
 * @param {string} datasetLabels.unspecific - Label for the unspecific dataset.
 * @param {string} datasetLabels.estimate - Label for the estimated dataset.
 *
 * @returns {number} - The total value in ADA for the processed earning entry.
 */ export const processEntry = (entry, labels, datasets, programId, datasetLabels, isDark)=>{
    let totalValue = 0;
    const valueAmount = Number(entry.value[0]?.amountInAda ?? "0");
    totalValue += valueAmount;
    if (entry.status === "Unverified") {
        entry.byPool.forEach((poolItem)=>handleUnverifiedData(poolItem, labels, datasets, programId, datasetLabels.estimate, isDark));
    } else if (entry.byPool.length) {
        entry.byPool.forEach((poolItem)=>handlePoolData(poolItem, labels, datasets, programId, isDark));
    } else {
        handleUnspecificData(totalValue, labels, datasets, programId, datasetLabels.unspecific, isDark);
    }
    return valueAmount;
};
export function transformData({ data, i18n, isDark }) {
    const grouped = groupEarningsByDateAndProgram(data, i18n.locale);
    const labels = [];
    const datasets = [];
    const lineData = new Array(data.length).fill(0);
    const dailyTotals = new Array(data.length).fill(0);
    const lineDates = new Set();
    for(const date in grouped){
        labels.push(date);
        let dailyTotal = 0;
        for(const programId in grouped[date]){
            let totalValue = 0;
            for (const entry of grouped[date][programId]){
                const valueAmount = processEntry(entry, labels, datasets, programId, i18n.datasetLabels, isDark);
                dailyTotal += valueAmount;
                totalValue += valueAmount;
            }
            if (!lineDates.has(date)) {
                lineDates.add(date);
                lineData[labels.length - 1] = totalValue;
            } else {
                lineData[labels.length - 1] += totalValue;
            }
        }
        dailyTotals[labels.length - 1] = dailyTotal;
    }
    const cumulativeLineData = handleCumulativeData([
        ...lineData
    ]);
    datasets.unshift({
        label: i18n.datasetLabels.totalPerDay,
        data: dailyTotals,
        type: "line",
        borderColor: primaryColor,
        backgroundColor: lighten(0.1, primaryColor),
        pointBackgroundColor: lighten(0.1, primaryColor),
        pointHoverRadius: 6,
        fill: false,
        tension: 0.2,
        yAxisID: "yBar"
    });
    datasets.unshift({
        label: i18n.datasetLabels.totalOverTime,
        data: cumulativeLineData,
        type: "line",
        borderColor: lighten(0.2, secondaryColor),
        pointBackgroundColor: lighten(0.2, secondaryColor),
        pointHoverRadius: 6,
        fill: false,
        tension: 0.2,
        yAxisID: "yLine"
    });
    return {
        labels,
        datasets: sortBy(datasets, "label"),
        groupedEarningsByDateAndProgram: grouped
    };
}
/**
 * Get the earnings data for a specified interval.
 *
 * @param {EChartInterval} chartInterval - The time interval for which the data should be shown.
 * @param {TEarningWithAdaAmount[]} earnings - An array of earnings with Ada amounts.
 *
 * @returns {TEarningWithAdaAmount[]} An array of earnings for the specified interval.
 */ export const getNumberOfDaysForEarningsChart = (chartInterval, earnings)=>{
    if (earnings.length === 0) {
        return [];
    }
    const maxDate = earnings.reduce((a, { date })=>date.format > a ? date.format : a, "");
    const currentDate = new Date();
    let startDate;
    const endDate = new Date(maxDate);
    switch(chartInterval){
        case EChartInterval["1W"]:
            startDate = subDays(subWeeks(currentDate, 1), 1);
            break;
        case EChartInterval["2W"]:
            startDate = subDays(subWeeks(currentDate, 2), 1);
            break;
        case EChartInterval["1M"]:
            startDate = subDays(subMonths(currentDate, 1), 1);
            break;
        case EChartInterval.MTD:
            startDate = startOfMonth(currentDate);
            break;
        case EChartInterval["3M"]:
            startDate = subDays(subMonths(currentDate, 3), 1);
            break;
        case EChartInterval.YTD:
            startDate = startOfYear(currentDate);
            break;
        case EChartInterval.ALL:
        default:
            return earnings; // For 'ALL' or default, return the entire array
    }
    return earnings.filter((earning)=>{
        const earningDate = new Date(earning.date.format);
        return startDate < endDate && isWithinInterval(earningDate, {
            start: startDate,
            end: endDate
        });
    });
};
/**
 * Calculate the earnings in ADA for a given emitted asset and Value (inside a `byPool` or `value` field).
 * The AssetAmount value is only used for assets > 0 decimals.
 * For zero decimal assets, the calculated `amountInAda` is already the correct value that can be used for rendering.
 *
 * @param {Asset} emittedAsset - The emitted asset details.
 * @param {Value} valueEntry - The value entry associated with the emitted asset.
 * @param {number} adaInUsd - The value of 1 ADA in USD.
 * @returns {number} - The earning amount in ADA. The value will have 0 decimals for zero decimal assets, otherwise it will have the correct number of decimals.
 */ export const getEarningAmountInAda = (valueEntry, emittedAsset, adaInUsd)=>{
    const assetInADA = calculateAssetPriceInADA(Number(emittedAsset?.priceToday), adaInUsd) || 0;
    const isZeroDecimalAsset = emittedAsset?.decimals === DEFAULT_DECIMALS;
    const amountInAda = assetInADA * Number(valueEntry.amount) || 0;
    const assetAmount = new AssetAmount(Math.round(amountInAda), emittedAsset);
    return isZeroDecimalAsset ? amountInAda : assetAmount.value.toNumber();
};
/**
 * Calculates the total earnings in ADA (Cardano) based on yield farming earnings history, asset data, and program information.
 *
 * @param {Object} params - Function parameters.
 * @param {IADAData["cardano"]} [params.adaData] - ADA (Cardano) data information.
 * @param {Asset[]} [params.emittedAssets] - Array of assets that are emitted by various programs.
 * @param {Program[]} [params.programs] - Array of different programs which emit assets.
 * @param {Earning[]} params.yieldFarmingEarningsHistory - History of yield farming earnings.
 * @returns {AssetAmount} - Returns the total earnings in ADA.
 */ export const getEarningsInADA = ({ adaData, emittedAssets, onlyClaimable = false, programs, yieldFarmingEarningsHistory })=>{
    const earnings = yieldFarmingEarningsHistory.reduce((acc, { value, ...rest })=>{
        const emittedAsset = emittedAssets?.find((asset)=>asset.assetId === programs?.find(({ id })=>id === rest.program.id)?.emittedAsset) ?? {};
        const amountInAda = getEarningAmountInAda(value[0], emittedAsset, adaData?.usd ?? 0);
        if (onlyClaimable && rest.status !== "Claimable") {
            return acc;
        }
        return acc + amountInAda;
    }, 0);
    return new AssetAmount(stringToBigint(earnings, ADA_METADATA.decimals), ADA_METADATA);
};
