export const FRACTION_INPUT_MAX_LENGTH = 79;
export const NUMBER_TABLE_ITEMS = 10;
export const INPUT_DEBOUNCE_IN_MS = 1000;
export const CONTROL_CENTER_GUIDES_NODE = "CONTROL_CENTER_GUIDES_NODE";
export const EARNINGS_COUNTDOWN_ID = "SUNDAESWAP_EARNINGS_COUNTDOWN";
export const HOME_V3_BUBBLES_CANVAS = "BUBBLES_CANVAS";
export const APP_NAME = "SundaeSwap DEX";
/* -------------------------------------------------------------------------------------------------
 * Taste Test
 * -----------------------------------------------------------------------------------------------*/ export const TASTE_TEST_DETAIL_COUNTDOWN_ID = "SUNDAESWAP_TASTE_TEST_COUNTDOWN";
export const TASTE_TEST_PENDING_TEASER_COUNTDOWN_ID = "TASTE_TEST_PENDING_TEASER_COUNTDOWN_ID";
export const TASTE_TEST_ONGOING_TEASER_COUNTDOWN_ID = "TASTE_TEST_ONGOING_TEASER_COUNTDOWN_ID";
export const TASTE_TEST_JUMBOTRON_ID = "taste-test-jumbotron";
export const TASTE_TEST_UPCOMING_TEASER_STACK_ID = "taste-tests-upcoming";
export const TASTE_TEST_USPS_ID = "taste-test-usps";
export const TASTE_TEST_FAQS_ID = "taste-test-faqs";
export const TASTE_TEST_OVERVIEW_TABLES_ID = "ongoing-taste-tests";
export const getFilterNodeId = (filterCategory)=>`filter-${filterCategory}`;
export const getPageTitle = (title)=>`${title} | ${APP_NAME}`;
export const userPrefersDarkMode = ()=>localStorage.theme === "dark" || !("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches;
