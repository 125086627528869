export var EPortfolioActions;
(function(EPortfolioActions) {
    EPortfolioActions["SET_ACTIVE_TAB_TITLE"] = "SET_ACTIVE_TAB_TITLE";
    EPortfolioActions["SET_ALL_ORDERS"] = "SET_ALL_ORDERS";
    EPortfolioActions["SET_FARM_POSITIONS"] = "SET_FARM_POSITIONS";
    EPortfolioActions["SET_FARMS_HISTORY"] = "SET_FARMS_HISTORY";
    EPortfolioActions["SET_FORMATTED_ORDERS"] = "SET_FORMATTED_ORDERS";
    EPortfolioActions["SET_IS_FETCHED_FARMS"] = "SET_IS_FETCHED_FARMS";
    EPortfolioActions["SET_IS_FETCHING_FARMS"] = "SET_IS_FETCHING_FARMS";
    EPortfolioActions["SET_IS_FETCHED_OPS"] = "SET_IS_FETCHED_OPS";
    EPortfolioActions["SET_IS_FETCHING_OPS"] = "SET_IS_FETCHING_OPS";
    EPortfolioActions["SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES"] = "SET_IS_LOADING_PORTFOLIO_BALANCE_VALUES";
    EPortfolioActions["SET_IS_REFETCHING_OPS"] = "SET_IS_REFETCHING_OPS";
    EPortfolioActions["SET_IS_SIDEBAR_COLLAPSED"] = "SET_IS_SIDEBAR_COLLAPSED";
    EPortfolioActions["SET_OPS_HISTORY"] = "SET_OPS_HISTORY";
    EPortfolioActions["SET_OPS_OPEN"] = "SET_OPS_OPEN";
    EPortfolioActions["SET_ORDERS_SEARCH_VALUE"] = "SET_ORDERS_SEARCH_VALUE";
    EPortfolioActions["SET_ORDER_STATUS_FILTER_ITEMS"] = "SET_ORDER_STATUS_FILTER_ITEMS";
    EPortfolioActions["SET_ORDER_STATUS_FILTER_VALUE"] = "SET_ORDER_STATUS_FILTER_VALUE";
    EPortfolioActions["SET_SELECTED_ASSET"] = "SET_SELECTED_ASSET";
    EPortfolioActions["SET_PORTFOLIO_BALANCE_VALUES"] = "SET_PORTFOLIO_BALANCE_VALUES";
    EPortfolioActions["SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA"] = "SET_TOTAL_PORTFOLIO_BALANCE_IN_ADA";
    EPortfolioActions["SET_ORDERS_TABLE_COLUMN_WIDTHS"] = "SET_ORDERS_TABLE_COLUMN_WIDTHS";
    EPortfolioActions["SET_ORDERS_TABLE_HEADERS"] = "SET_ORDERS_TABLE_HEADERS";
})(EPortfolioActions || (EPortfolioActions = {}));
export var FreezerItemStatus;
(function(FreezerItemStatus) {
    FreezerItemStatus["FREEZER_LOCK"] = "freezerLock";
    FreezerItemStatus["FREEZER_UNLOCK"] = "freezerUnlock";
})(FreezerItemStatus || (FreezerItemStatus = {}));
