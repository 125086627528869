import { EProfileAppActions } from "./types";
export const ProfileAppReducer = (prevState, action, defaultState)=>{
    let newState;
    switch(action.type){
        /* -------------------------------------------------------------------------------------------------
     * App
     * -----------------------------------------------------------------------------------------------*/ case EProfileAppActions.SET_ERROR_REPORTING:
            newState = {
                ...prevState,
                errorReportingEnabled: action.payload.errorReportingEnabled
            };
            break;
        case EProfileAppActions.SET_HOTJAR_ENABLED:
            newState = {
                ...prevState,
                hotjarEnabled: action.payload.hotjarEnabled
            };
            break;
        case EProfileAppActions.SET_CARDANO_EXPLORER:
            newState = {
                ...prevState,
                cardanoExplorer: action.payload.cardanoExplorer
            };
            break;
        case EProfileAppActions.SET_PRIVACY_MODE:
            newState = {
                ...prevState,
                privacyEnabled: action.payload.privacyEnabled
            };
            break;
        case EProfileAppActions.SET_ACTIVE_LANGUAGE:
            newState = {
                ...prevState,
                activeLanguage: action.payload.activeLanguage
            };
            break;
        case EProfileAppActions.SET_PREFERRED_HANDLE:
            newState = {
                ...prevState,
                handle: action.payload.handle
            };
            break;
        case EProfileAppActions.SET_PREFERRED_AVATAR:
            newState = {
                ...prevState,
                avatar: action.payload.avatar
            };
            break;
        case EProfileAppActions.SET_PREFERS_REDUCED_MOTION:
            newState = {
                ...prevState,
                prefersReducedMotion: action.payload.prefersReducedMotion
            };
            break;
        case EProfileAppActions.SYNC_DEFAULT_STATE:
            newState = {
                ...prevState,
                ...action.payload.state
            };
            break;
        case EProfileAppActions.LOGOUT:
            return defaultState;
        /* -------------------------------------------------------------------------------------------------
     * Limit Orders
     * -----------------------------------------------------------------------------------------------*/ case EProfileAppActions.ADD_LIMIT_ORDER:
            const limitOrders = [
                ...prevState.limitOrders,
                action.payload.txHash
            ];
            newState = {
                ...prevState,
                limitOrders: [
                    ...new Set(limitOrders)
                ]
            };
            break;
        /* -------------------------------------------------------------------------------------------------
     * TX Settings
     * -----------------------------------------------------------------------------------------------*/ case EProfileAppActions.SET_SLIPPAGE_TOLERANCE:
            newState = {
                ...prevState,
                slippageTolerance: action.payload.slippageTolerance
            };
            break;
        case EProfileAppActions.SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO:
            newState = {
                ...prevState,
                isSlippageToleranceSetToAuto: action.payload.isSlippageToleranceSetToAuto
            };
            break;
        /* -------------------------------------------------------------------------------------------------
     * Farms
     * -----------------------------------------------------------------------------------------------*/ case EProfileAppActions.SET_FARMS_VIEW_MODE:
            if (prevState.farmsViewMode === action.payload.farmsViewMode) {
                return prevState;
            }
            newState = {
                ...prevState,
                farmsViewMode: action.payload.farmsViewMode
            };
            break;
        case EProfileAppActions.SET_FARMS_FILTER:
            if (prevState.farmsFilter === action.payload.farmsFilter) {
                return prevState;
            }
            newState = {
                ...prevState,
                farmsFilter: action.payload.farmsFilter
            };
            break;
        /* -------------------------------------------------------------------------------------------------
     * Pool Filters
     * -----------------------------------------------------------------------------------------------*/ case EProfileAppActions.SET_POOL_FILTERS:
            newState = {
                ...prevState,
                filters: {
                    ...prevState.filters,
                    ...action.payload.poolFilters
                }
            };
            break;
        case EProfileAppActions.SET_FAVORITE_POOL:
            const favoritePools = [
                ...prevState.favoritePools,
                action.payload.poolId
            ];
            newState = {
                ...prevState,
                favoritePools: [
                    ...new Set(favoritePools)
                ]
            };
            break;
        case EProfileAppActions.UNSET_FAVORITE_POOL:
            newState = {
                ...prevState,
                favoritePools: prevState.favoritePools.filter((poolId)=>poolId !== action.payload.poolId)
            };
            break;
        /* -------------------------------------------------------------------------------------------------
     * Toggles
     * -----------------------------------------------------------------------------------------------*/ case EProfileAppActions.SET_TOGGLES:
            newState = {
                ...prevState,
                toggles: {
                    ...prevState.toggles,
                    ...action.payload.toggles
                }
            };
            break;
        default:
            return prevState;
    }
    return newState;
};
