import { usePortfolioBalanceValues } from "../../../hooks/portfolio/usePortfolioBalanceValues";
import { useActiveTabTitle } from "./useActiveTabTitle";
import { useFarms } from "./useFarms";
/**
 * This hook contains side-effects for the PortfolioContext.
 *
 * @function
 * @returns {void}
 */ export const usePortfolioContextEffects = ()=>{
    useActiveTabTitle();
    useFarms();
    usePortfolioBalanceValues();
};
