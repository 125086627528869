export var EOrderActions;
(function(EOrderActions) {
    EOrderActions["RESET_ORDER_FLOW"] = "RESET_ORDER_FLOW";
    EOrderActions["SET_ADA_AFTER_SWAP"] = "SET_ADA_AFTER_SWAP";
    EOrderActions["SET_GIVEN_AMOUNT"] = "SET_GIVEN_AMOUNT";
    EOrderActions["SET_GIVEN_UTXO_AMOUNT"] = "SET_GIVEN_UTXO_AMOUNT";
    EOrderActions["SET_GIVEN_EXCEEDS_BALANCE"] = "SET_GIVEN_EXCEEDS_BALANCE";
    EOrderActions["SET_ORDER_CONSENT"] = "SET_ORDER_CONSENT";
    EOrderActions["SET_ORDER_FLOW_STATE"] = "SET_ORDER_FLOW_STATE";
    EOrderActions["SET_ORDER_TYPE"] = "SET_ORDER_TYPE";
    EOrderActions["SET_RATIO"] = "SET_RATIO";
    EOrderActions["SET_TAKEN_AMOUNT"] = "SET_TAKEN_AMOUNT";
    EOrderActions["SET_TAKEN_EXCEEDS_RESERVES"] = "SET_TAKEN_EXCEEDS_RESERVES";
    EOrderActions["SET_TRANSACTION"] = "SET_TRANSACTION";
    EOrderActions["SET_TRANSACTION_STATE"] = "SET_TRANSACTION_STATE";
    EOrderActions["SWAP_ORDER_DIRECTION"] = "SWAP_ORDER_DIRECTION";
    EOrderActions["SET_FETCHING_POOL"] = "SET_FETCHING_POOL";
    EOrderActions["SET_PROTOCOL_VERSION"] = "SET_PROTOCOL_VERSION";
    EOrderActions["SET_ORDER_ROUTE"] = "SET_ORDER_ROUTE";
    EOrderActions["UNSET_ORDER_ROUTE"] = "UNSET_ORDER_ROUTE";
})(EOrderActions || (EOrderActions = {}));
export var EOrderType;
(function(EOrderType) {
    EOrderType["market"] = "market";
    EOrderType["limit"] = "limit";
})(EOrderType || (EOrderType = {}));
export var EOrderTransactionBuilderState;
(function(EOrderTransactionBuilderState) {
    EOrderTransactionBuilderState["BUILD"] = "BUILD";
    EOrderTransactionBuilderState["SUCCESS"] = "SUCCESS";
    EOrderTransactionBuilderState["ERROR"] = "ERROR";
})(EOrderTransactionBuilderState || (EOrderTransactionBuilderState = {}));
export var EOrderFlowState;
(function(EOrderFlowState) {
    EOrderFlowState["initial"] = "initial";
    EOrderFlowState["preview"] = "preview";
    EOrderFlowState["previewCancelled"] = "previewCancelled";
    EOrderFlowState["submitting"] = "submitting";
    EOrderFlowState["submittingCancelled"] = "submittingCancelled";
    EOrderFlowState["success"] = "success";
    EOrderFlowState["error"] = "error";
    EOrderFlowState["reset"] = "reset";
})(EOrderFlowState || (EOrderFlowState = {}));
