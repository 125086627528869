import { AssetAmount } from "@sundaeswap/asset";
import { OrderActions } from "../../../stores/OrderContext";
export const handleInputChange = (value, context, asset, dispatch)=>{
    if (value === asset?.value?.toNumber()) {
        return;
    }
    if (context === "given") {
        OrderActions.setGivenAmount(AssetAmount.fromValue(value ?? 0n, asset?.metadata), dispatch, `AssetInputs.handleInputChange.${context}`);
    } else {
        OrderActions.setTakenAmount(AssetAmount.fromValue(value ?? 0n, asset?.metadata), dispatch, `AssetInputs.handleInputChange.${context}`);
    }
};
export const normalizeInputValue = (asset)=>asset?.amount === 0n ? "" : asset?.value?.toString();
