import { createClient } from "@prismicio/client";
/**
 * We are hardcoding them here as they would be exposed in the users browser anyway.
 * This is due to the way the Prismic SDK handles its request. The `accessToken` is sent as a query parameter in the request and therefore is visible to the user.
 * Hardcoding them in the application makes it easier to change them in the future as we wouldn't have to change them in the AWS Pipeline configuration.
 */ const PRISMIC_REPO_NAME = "sundae-labs-website";
const PRISMIC_CLIENT_TOKEN = "MC5aQnJEOGhBQUFDRUFnbnJJ.77-9Pu-_vVXvv73vv73vv70HVe-_vX8M77-977-977-9EwMz77-977-977-977-9Xxvvv73vv73vv70LZe-_vRF7";
const apiEndpoint = `https://${PRISMIC_REPO_NAME}.cdn.prismic.io/api/v2`;
export const prismicClient = createClient(apiEndpoint, {
    accessToken: PRISMIC_CLIENT_TOKEN
});
export const fetchPrismicNewsByEveryTags = async (tags)=>{
    const response = await prismicClient.getByEveryTag([
        "news",
        ...tags
    ], {
        orderings: [
            {
                field: "document.first_publication_date",
                direction: "desc"
            }
        ]
    });
    return response?.results;
};
