import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { decodeSearchParams } from "../../utils/routes.utils";
import useAppLocation from "./useAppLocation";
const useAppNavigate = ()=>{
    const [searchParams] = useSearchParams();
    const routerNavigate = useNavigate();
    const { pathname } = useAppLocation();
    const currentSearchParams = decodeSearchParams(searchParams);
    const navigate = (args)=>{
        const { hash, to, search } = args;
        routerNavigate({
            pathname: to || pathname,
            ...hash && {
                hash
            },
            ...search && {
                search: `${createSearchParams(typeof search === "function" ? search(currentSearchParams) : search)}`
            }
        });
    };
    return navigate;
};
export default useAppNavigate;
