import { ELocalControlCenterStateActions, EPersistentControlCenterStateActions } from "./types";
/* -------------------------------------------------------------------------------------------------
 * Persistent Control Center Reducer
 * -----------------------------------------------------------------------------------------------*/ const PersistentControlCenterStateReducer = (state, action)=>{
    switch(action.type){
        case EPersistentControlCenterStateActions.START_GUIDE:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    currentGuideId: action.payload.currentGuideId,
                    guideInfo: {
                        ...state.guides.guideInfo,
                        [action.payload.currentGuideId]: {
                            completed: !!state.guides.guideInfo[action.payload.currentGuideId]?.completed,
                            stepIndex: state.guides.guideInfo[action.payload.currentGuideId]?.stepIndex || 0
                        }
                    }
                }
            };
        case EPersistentControlCenterStateActions.OPEN_GUIDES_ON_MOUNT:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        openGuidesOnMount: action.payload.openGuidesOnMount
                    }
                };
            }
        case EPersistentControlCenterStateActions.COMPLETE_GUIDE:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        guideInfo: {
                            ...state.guides.guideInfo,
                            [action.payload.currentGuideId]: {
                                completed: action.payload.completed,
                                stepIndex: 0
                            }
                        }
                    }
                };
            }
        case EPersistentControlCenterStateActions.CLOSE_GUIDE:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        guideInfo: {
                            ...state.guides.guideInfo,
                            [action.payload.currentGuideId]: {
                                completed: action.payload.completed,
                                stepIndex: action.payload.stepIndex
                            }
                        }
                    }
                };
            }
        case EPersistentControlCenterStateActions.HANDLE_STEP:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        guideInfo: {
                            ...state.guides.guideInfo,
                            [action.payload.currentGuideId]: {
                                ...state.guides.guideInfo[action.payload.currentGuideId],
                                stepIndex: action.payload.stepIndex
                            }
                        }
                    }
                };
            }
        case EPersistentControlCenterStateActions.SET_IS_EVERY_CHAPTER_COMPLETED:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        isEveryChapterCompleted: action.payload.isEveryChapterCompleted
                    }
                };
            }
        default:
            return state;
    }
};
/* -------------------------------------------------------------------------------------------------
 * Local Control Center Reducer
 * -----------------------------------------------------------------------------------------------*/ const LocalControlCenterStateReducer = (state, action)=>{
    switch(action.type){
        case ELocalControlCenterStateActions.LOAD_CHAPTERS:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    chapters: action.payload.chapters
                }
            };
        case ELocalControlCenterStateActions.RUN_GUIDE:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    runGuide: action.payload.runGuide
                }
            };
        case ELocalControlCenterStateActions.SET_ACTIVE_CHAPTER:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    activeChapter: action.payload.activeChapter
                }
            };
        case ELocalControlCenterStateActions.SET_ACTIVE_GUIDE:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    activeGuideId: action.payload.activeGuideId
                }
            };
        case ELocalControlCenterStateActions.SET_LAST_CLOSED_GUIDE_ID:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    lastClosedGuideItemId: action.payload.lastClosedGuideItemId
                }
            };
        case ELocalControlCenterStateActions.SET_WITH_ANIMATION_DELAY:
            return {
                ...state,
                withAnimationDelay: action.payload.withAnimationDelay
            };
        case ELocalControlCenterStateActions.SET_IS_NEXT_BUTTON_DISABLED:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    isNextButtonDisabled: action.payload.isNextButtonDisabled
                }
            };
        case ELocalControlCenterStateActions.SHOW_GUIDES:
            {
                return {
                    ...state,
                    showGuides: action.payload.showGuides
                };
            }
        case ELocalControlCenterStateActions.SHOW_SUCCESS_ANIMATION:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        showSuccessAnimation: action.payload.showSuccessAnimation
                    }
                };
            }
        case ELocalControlCenterStateActions.SHOW_TOOLBAR:
            {
                return {
                    ...state,
                    showToolbar: action.payload.showToolbar
                };
            }
        case ELocalControlCenterStateActions.SHOW_HELP_CENTER:
            {
                return {
                    ...state,
                    showHelpCenter: action.payload.showHelpCenter
                };
            }
        case ELocalControlCenterStateActions.SHOW_INBOX:
            {
                return {
                    ...state,
                    showInbox: action.payload.showInbox
                };
            }
        case ELocalControlCenterStateActions.SHOW_GUIDE_CLOSE_UI:
            {
                return {
                    ...state,
                    guides: {
                        ...state.guides,
                        showGuideCloseUI: action.payload.showGuideCloseUI
                    }
                };
            }
        case ELocalControlCenterStateActions.SET_LAST_CLOSED_NEWS_ID:
            return {
                ...state,
                news: {
                    ...state.news,
                    lastClosedNewsId: action.payload.lastClosedNewsId
                }
            };
        case ELocalControlCenterStateActions.SHOW_LOADING_ANIMATION_GUIDES:
            return {
                ...state,
                guides: {
                    ...state.guides,
                    showLoadingAnimation: action.payload.showLoadingAnimation
                }
            };
        default:
            return state;
    }
};
export { LocalControlCenterStateReducer, PersistentControlCenterStateReducer };
