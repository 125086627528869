export const LOCAL_STORAGE_KEY = "SUNDAESWAP_EXCHANGE_LOCAL_CACHE";
export const LOCAL_PENDING_ORDERS_SLUG = "SUNDAESWAP_EXCHANGE_PENDING_ORDERS";
export const LOCAL_CANCELLED_ORDERS_SLUG = "SUNDAESWAP_EXCHANGE_CANCELLED_ORDERS";
export const LOCAL_STORAGE_FIRST_VISIT_KEY = "SUNDAESWAP_EXCHANGE_FIRST_VISIT";
export const LOCAL_STORAGE_NEWS_READ_KEY = "SUNDAESWAP_EXCHANGE_NEWS_READ_KEY";
export const LOCAL_STORAGE_PENDING_YF_TRANSACTIONS = "SUNDAESWAP_EXCHANGE_PENDING_YF_TRANSACTIONS";
export const LOCAL_STORAGE_TASTE_TEST_CONTRIBUTIONS = "SUNDAESWAP_TASTE_TEST_CONTRIBUTIONS";
export const LOCAL_STORAGE_CONTROL_CENTER = "SUNDAESWAP_CONTROL_CENTER";
export const LOCAL_STORAGE_APP_VERSION = "SUNDAESWAP_APP_VERSION";
export const LOCAL_STORAGE_TASTE_TEST_TARGET_PRICE = "SUNDAESWAP_TASTE_TEST_TARGET_PRICE";
export const LOCAL_STORAGE_SHOULD_SEND_COUNTER_EVENT = "SUNDAESWAP_SHOULD_SEND_COUNTER_EVENT";
