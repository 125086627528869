import gql from "graphql-tag";
export var Coin;
(function(Coin) {
    Coin["CoinA"] = "CoinA";
    Coin["CoinB"] = "CoinB";
})(Coin || (Coin = {}));
export var Direction;
(function(Direction) {
    Direction["Buy"] = "Buy";
    Direction["Sell"] = "Sell";
})(Direction || (Direction = {}));
export var FreezerStatus;
(function(FreezerStatus) {
    /** No rewards have matured yet */ FreezerStatus["Allocating"] = "Allocating";
    /** Rewards have matured and can be harvested */ FreezerStatus["Harvested"] = "Harvested";
    FreezerStatus["Immature"] = "Immature";
    /** Rewards are mature, but still being allocated, so are unable to be harvested */ FreezerStatus["Mature"] = "Mature";
})(FreezerStatus || (FreezerStatus = {}));
export var OpType;
(function(OpType) {
    OpType["CreatePool"] = "CreatePool";
    OpType["DepositMixed"] = "DepositMixed";
    OpType["DepositSingle"] = "DepositSingle";
    OpType["Swap"] = "Swap";
    OpType["Withdraw"] = "Withdraw";
})(OpType || (OpType = {}));
export var Rank;
(function(Rank) {
    Rank["RankTvl"] = "RankTVL";
})(Rank || (Rank = {}));
export var Resolution;
(function(Resolution) {
    Resolution["Canceled"] = "Canceled";
    Resolution["Harvested"] = "Harvested";
    Resolution["Scooped"] = "Scooped";
})(Resolution || (Resolution = {}));
export var SortType;
(function(SortType) {
    SortType["Gainers"] = "Gainers";
    SortType["Name"] = "Name";
    SortType["Recent"] = "Recent";
    SortType["Tvl"] = "TVL";
    SortType["Trending"] = "Trending";
    SortType["Volume"] = "Volume";
})(SortType || (SortType = {}));
export var TokenMetadataSource;
(function(TokenMetadataSource) {
    TokenMetadataSource["CardanoTokenRegistry"] = "CardanoTokenRegistry";
    TokenMetadataSource["OnChainLabel20"] = "OnChainLabel20";
    TokenMetadataSource["OnChainLabel721"] = "OnChainLabel721";
})(TokenMetadataSource || (TokenMetadataSource = {}));
export var VolumeInterval;
(function(VolumeInterval) {
    VolumeInterval["Daily"] = "Daily";
    VolumeInterval["Monthly"] = "Monthly";
    VolumeInterval["Weekly"] = "Weekly";
})(VolumeInterval || (VolumeInterval = {}));
export var YieldTerm;
(function(YieldTerm) {
    YieldTerm["Term30"] = "Term30";
})(YieldTerm || (YieldTerm = {}));
export const OpFragmentFragmentDoc = gql`
  fragment OpFragment on Op {
    __typename
    ... on OpSwap {
      swapGot: got
      swapQuantityA: quantityA
      swapQuantityB: quantityB
      swapWant: want
    }
    ... on OpDepositMixed {
      depositMixedLq: lq
      depositMixedQuantityA: quantityA
      depositMixedQuantityB: quantityB
    }
    ... on OpDepositSingle {
      depositSingleLq: lq
      depositSingleQuantityA: quantityA
      depositSingleQuantityB: quantityB
    }
    ... on OpWithdraw {
      withdrawLq: lq
      withdrawQuantityA: quantityA
      withdrawQuantityB: quantityB
    }
  }
`;
export const AssetFragmentFragmentDoc = gql`
  fragment AssetFragment on Asset {
    assetId
    policyId
    assetName
    decimals
    logo
    ticker
    dateListed
    marketCap
    sources
    priceToday
    priceYesterday
    priceDiff24H
    poolId
    slotAdded
    totalSupply
    tvl
    tvlDiff24H
    tvlToday
    tvlYesterday
    volume24H
  }
`;
export const PoolFragmentFragmentDoc = gql`
  fragment PoolFragment on Pool {
    apr
    rewards {
      farmProgram {
        totalStaked
        rewardConfig {
          DateEnd
          DateStart
          Id
        }
      }
      apr
      assetID
      asset {
        ...AssetFragment
      }
    }
    assetA {
      ...AssetFragment
    }
    assetB {
      ...AssetFragment
    }
    assetLP {
      ...AssetFragment
    }
    name
    fees24H
    quantityA
    quantityB
    quantityLP
    ident
    assetID
    version
  }
`;
export const OperationFragmentFragmentDoc = gql`
  fragment OperationFragment on Operation {
    createdAt {
      format(layout: "2006-01-02T15:04:05-0700")
    }
    address
    op {
      __typename
      ...OpFragment
    }
    outcome {
      feeA
      feeB
      quantityA
      quantityB
      quantityLP
      price {
        unit
        value
      }
    }
    pool {
      ...PoolFragment
    }
    spentAt {
      format(layout: "2006-01-02T15:04:05-0700")
    }
    spentReason
    spentTxIn {
      index
      txHash
    }
    txIn {
      index
      txHash
    }
  }
`;
export const PoolInfoFragmentFragmentDoc = gql`
  fragment PoolInfoFragment on Pool {
    tvl
    name
    priceUSD
  }
`;
export const ExtendPoolFragmentFragmentDoc = gql`
  fragment ExtendPoolFragment on Pool {
    ...PoolFragment
    ...PoolInfoFragment
  }
`;
export const UtxoFragmentFragmentDoc = gql`
  fragment UtxoFragment on Utxo {
    address
    txHash
    index
    coins
    datum
    datumHash
    assets {
      quantity
      assetID
    }
  }
`;
export const FreezerItemFragmentFragmentDoc = gql`
  fragment FreezerItemFragment on FreezerItem {
    __typename
    assetID
    earned
    rewards {
      asset {
        ...AssetFragment
      }
      quantity
    }
    rewardsMatured {
      asset {
        ...AssetFragment
      }
      quantity
    }
    startDate {
      format(layout: "2006-01-02T15:04:05Z")
    }
    spentAt {
      format(layout: "2006-01-02T15:04:05Z")
    }
    nextRewardAt {
      format(layout: "2006-01-02T15:04:05Z")
    }
    quantity
    term
    utxo {
      ...UtxoFragment
    }
    pool {
      ...PoolFragment
    }
    spentTxHash
  }
`;
export const LiquidityFragmentFragmentDoc = gql`
  fragment LiquidityFragment on Liquidity {
    pool {
      ...PoolFragment
    }
    poolShare
    lpTokens {
      asset {
        ...AssetFragment
      }
      quantity
    }
    feesA {
      asset {
        ...AssetFragment
      }
      quantity
    }
    feesB {
      asset {
        ...AssetFragment
      }
      quantity
    }
  }
`;
export const LiquidityFeesFragmentFragmentDoc = gql`
  fragment LiquidityFeesFragment on Liquidity {
    pool {
      ...PoolFragment
    }
    feesA {
      asset {
        ...AssetFragment
      }
      quantity
    }
    feesB {
      asset {
        ...AssetFragment
      }
      quantity
    }
  }
`;
export const GetOperationsOpenDocument = gql`
  query getOperationsOpen($address: String!, $pageSize: Int, $token: String) {
    opOpen(address: $address, pageSize: $pageSize, token: $token) {
      ops {
        ...OperationFragment
      }
      token
    }
  }
  ${OperationFragmentFragmentDoc}
  ${OpFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetOperationsHistoryDocument = gql`
  query getOperationsHistory(
    $address: String!
    $pageSize: Int
    $token: String
  ) {
    opHistory(address: $address, pageSize: $pageSize, token: $token) {
      ops {
        ...OperationFragment
      }
      token
    }
  }
  ${OperationFragmentFragmentDoc}
  ${OpFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetOpenOrdersDocument = gql`
  query getOpenOrders($pageSize: Int, $poolIdent: String!) {
    openOrders(pageSize: $pageSize, poolIdent: $poolIdent) {
      ops {
        ...OperationFragment
      }
    }
  }
  ${OperationFragmentFragmentDoc}
  ${OpFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetPopularPoolsDocument = gql`
  query getPopularPools($pageSize: Int) {
    poolsPopular(pageSize: $pageSize) {
      ...ExtendPoolFragment
    }
  }
  ${ExtendPoolFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${PoolInfoFragmentFragmentDoc}
`;
export const GetPoolByIdentDocument = gql`
  query getPoolByIdent($ident: String!) {
    poolByIdent(ident: $ident) {
      ...ExtendPoolFragment
    }
  }
  ${ExtendPoolFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${PoolInfoFragmentFragmentDoc}
`;
export const SearchPoolsDocument = gql`
  query searchPools($query: String!) {
    pools(query: $query) {
      ...PoolFragment
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const SearchPoolsWithStatsDocument = gql`
  query searchPoolsWithStats(
    $query: String!
    $dateFrom: String!
    $dateTo: String!
    $interval: VolumeInterval!
    $currency: String!
  ) {
    pools(query: $query) {
      ...PoolFragment
      fees24H
      feeApr
      stats(
        dateFrom: $dateFrom
        dateTo: $dateTo
        interval: $interval
        currency: $currency
      ) {
        lockedValue
        tradingValue
        tradingVolume
      }
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetAssetsByIdsDocument = gql`
  query getAssetsByIds($ids: [String!]!) {
    assets(ids: $ids) {
      ...AssetFragment
    }
  }
  ${AssetFragmentFragmentDoc}
`;
export const GetPoolsByAssetIdsDocument = gql`
  query getPoolsByAssetIds($assetIds: [String!]!, $pageSize: Int, $page: Int) {
    pools(assetIds: $assetIds, pageSize: $pageSize, page: $page) {
      ...PoolFragment
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetPositionsDocument = gql`
  query getPositions($wallet: String!, $page: Int!, $pageSize: Int!) {
    positions(wallet: $wallet) {
      liquidity {
        liquidity(page: $page, pageSize: $pageSize) {
          ...LiquidityFragment
        }
      }
    }
  }
  ${LiquidityFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const FreezerOpenDocument = gql`
  query freezerOpen($address: String!, $pageSize: Int, $token: String) {
    freezerOpen(address: $address, pageSize: $pageSize, token: $token) {
      token
      items {
        ...FreezerItemFragment
      }
    }
    now {
      format(layout: "2006-01-02T15:04:05Z")
    }
  }
  ${FreezerItemFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${UtxoFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
`;
export const FreezerHistoryDocument = gql`
  query freezerHistory(
    $address: String!
    $dateFrom: String
    $dateTo: String
    $pageSize: Int
    $token: String
  ) {
    freezerHistory(
      address: $address
      pageSize: $pageSize
      token: $token
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      token
      items {
        ...FreezerItemFragment
      }
    }
  }
  ${FreezerItemFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${UtxoFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
`;
export const GetFactoryDataDocument = gql`
  query getFactoryData {
    factory {
      txHash
      index
      datum
      datumHash
      address
      coins
      assets {
        assetID
        quantity
      }
    }
  }
`;
export const GetPoolsByPairDocument = gql`
  query getPoolsByPair($coinA: String!, $coinB: String!) {
    poolsByPair(coinA: $coinA, coinB: $coinB) {
      ...PoolFragment
    }
  }
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetTaxReportsDocument = gql`
  query getTaxReports($wallet: String!) {
    tax_reports(wallet: $wallet) {
      wallet
      year
      status
      error
      download_url
    }
  }
`;
export const GenerateTaxReportDocument = gql`
  mutation generateTaxReport($wallet: String!, $year: Int!) {
    generate_tax_report(wallet: $wallet, year: $year) {
      wallet
      year
      status
      error
      download_url
    }
  }
`;
export const GetFeesDocument = gql`
  query getFees($queries: [FeeQuery!]!, $page: Int!, $pageSize: Int!) {
    fees(queries: $queries) {
      liquidity(page: $page, pageSize: $pageSize) {
        ...LiquidityFeesFragment
      }
    }
  }
  ${LiquidityFeesFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
`;
export const GetPoolsByIdentDocument = gql`
  query getPoolsByIdent($idents: [String!]!) {
    poolsByIdent(idents: $idents) {
      ...ExtendPoolFragment
    }
  }
  ${ExtendPoolFragmentFragmentDoc}
  ${PoolFragmentFragmentDoc}
  ${AssetFragmentFragmentDoc}
  ${PoolInfoFragmentFragmentDoc}
`;
export const GetAdaPriceDocument = gql`
  query getAdaPrice {
    adaPrice
  }
`;
const defaultWrapper = (action, _operationName, _operationType)=>action();
export function getSdk(client, withWrapper = defaultWrapper) {
    return {
        getOperationsOpen (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetOperationsOpenDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getOperationsOpen", "query");
        },
        getOperationsHistory (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetOperationsHistoryDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getOperationsHistory", "query");
        },
        getOpenOrders (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetOpenOrdersDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getOpenOrders", "query");
        },
        getPopularPools (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPopularPoolsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPopularPools", "query");
        },
        getPoolByIdent (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPoolByIdentDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPoolByIdent", "query");
        },
        searchPools (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(SearchPoolsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "searchPools", "query");
        },
        searchPoolsWithStats (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(SearchPoolsWithStatsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "searchPoolsWithStats", "query");
        },
        getAssetsByIds (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetAssetsByIdsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getAssetsByIds", "query");
        },
        getPoolsByAssetIds (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPoolsByAssetIdsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPoolsByAssetIds", "query");
        },
        getPositions (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPositionsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPositions", "query");
        },
        freezerOpen (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FreezerOpenDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "freezerOpen", "query");
        },
        freezerHistory (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FreezerHistoryDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "freezerHistory", "query");
        },
        getFactoryData (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetFactoryDataDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getFactoryData", "query");
        },
        getPoolsByPair (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPoolsByPairDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPoolsByPair", "query");
        },
        getTaxReports (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetTaxReportsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getTaxReports", "query");
        },
        generateTaxReport (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GenerateTaxReportDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "generateTaxReport", "mutation");
        },
        getFees (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetFeesDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getFees", "query");
        },
        getPoolsByIdent (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetPoolsByIdentDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getPoolsByIdent", "query");
        },
        getAdaPrice (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(GetAdaPriceDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "getAdaPrice", "query");
        }
    };
}
