export const isWrongNetwork = (network)=>// Changed to a check for ".includes('mainnet') since we are also testing for the eternl environment
    network === 1 && !window.__APP_CONFIG.envName.includes("mainnet") || network === 0 && window.__APP_CONFIG.envName.includes("mainnet");
export const getEnv = ()=>[
        "eternl-mainnet",
        "mainnet"
    ].includes(window.__APP_CONFIG.envName) ? "mainnet" : "preview";
export const checkIsEternlEnv = ()=>window.__APP_CONFIG.envName.includes("eternl");
/**
 * Checks if the Eternl dApp bridge is available.
 * @returns {boolean} Whether the Eternl dApp bridge is available.
 */ /* eslint-disable @typescript-eslint/no-explicit-any */ export const checkIsEternlDappBridgeAvailable = ()=>Boolean(window.cardano?.["eternl"]?.isBridge);
