import { ENewsActions } from "./types";
const setReadNewsItem = (newsRead, dispatch)=>dispatch({
        type: ENewsActions.SET_READ_NEWS_ITEM,
        payload: {
            newsRead
        }
    });
const setUnreadNewsItem = (newsRead, dispatch)=>dispatch({
        type: ENewsActions.SET_UNREAD_NEWS_ITEM,
        payload: {
            newsRead
        }
    });
const setReadAllNewsItems = (newsRead, dispatch)=>dispatch({
        type: ENewsActions.SET_READ_ALL_NEWS_ITEMS,
        payload: {
            newsRead
        }
    });
const setForceOpenedNewsId = (forceOpened, dispatch)=>dispatch({
        type: ENewsActions.SET_FORCE_OPENED_NEWS_ID,
        payload: {
            forceOpened
        }
    });
/* -------------------------------------------------------------------------------------------------
 * Export
 * -----------------------------------------------------------------------------------------------*/ export const NewsActions = {
    setReadNewsItem,
    setUnreadNewsItem,
    setReadAllNewsItems,
    setForceOpenedNewsId
};
