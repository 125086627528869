export var EErrorType;
(function(EErrorType) {
    EErrorType["error"] = "error";
    EErrorType["info"] = "info";
    EErrorType["warning"] = "warning";
})(EErrorType || (EErrorType = {}));
export var EMigrationTransactionBuilderState;
(function(EMigrationTransactionBuilderState) {
    EMigrationTransactionBuilderState["BUILD"] = "BUILD";
    EMigrationTransactionBuilderState["SUCCESS"] = "SUCCESS";
    EMigrationTransactionBuilderState["ERROR"] = "ERROR";
})(EMigrationTransactionBuilderState || (EMigrationTransactionBuilderState = {}));
