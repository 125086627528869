import isEmpty from "lodash/isEmpty";
/**
 * Calculates the progress of each guide in a chapter.
 *
 * @param {IChapter} chapter - The chapter object containing guides.
 * @param {Record<string, IPersistentGuide>} guideInfo - A record of guide information, keyed by Joyride Guide ID.
 * @returns {Array} An array of objects, each representing the progress of a guide within the chapter.
 */ export const getChapterProgress = (chapter, guideInfo)=>{
    return chapter.guides.map((guide)=>{
        const joyrideId = guide.joyride.id;
        const objectEntry = guideInfo[joyrideId];
        if (objectEntry) {
            const completed = objectEntry.completed;
            let percentage = 0;
            if (!completed) {
                percentage = objectEntry.stepIndex / guide.joyride.steps.length * 100;
            }
            return {
                id: joyrideId,
                completed,
                percentage
            };
        } else {
            return {
                id: joyrideId,
                completed: false,
                percentage: 0
            };
        }
    }).filter((entry)=>!!entry);
};
/**
 * Determines if every guide in a chapter is completed.
 *
 * @param {ReturnType<typeof getChapterProgress>} chapterProgress - An array of guide progress objects.
 * @returns {boolean} True if every guide in the chapter is completed, false otherwise.
 */ export const getIsEveryGuideInChapterCompleted = (chapterProgress)=>!isEmpty(chapterProgress) && chapterProgress.every((item)=>item?.completed);
/**
 * Counts the number of completed guides in a chapter.
 *
 * @param {ReturnType<typeof getChapterProgress>} chapterProgress - An array of guide progress objects.
 * @returns {number} The number of completed guides in the chapter.
 */ export const getAmountOfCompletedGuidesByChapter = (chapterProgress)=>chapterProgress.filter((item)=>item?.completed)?.length ?? 0;
