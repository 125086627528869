import { useI18N } from "@sundaeswap/react-hooks";
import { useMemo } from "react";
import { joyrideIds, joyrideSelectors } from "../../../../../constants/joyride.constants";
import { paths, PortfolioTab } from "../../../../../pages/routes";
import { EGuideChapter } from "../../../../../stores/ControlCenterContext";
export const usePortfolioBalanceGuideJoyride = ()=>{
    const { t } = useI18N("joyride");
    return useMemo(()=>({
            rank: 1,
            description: t("portfolio.guides.balance.description"),
            title: t("portfolio.guides.balance.title"),
            chapter: EGuideChapter.PORTFOLIO,
            joyride: {
                id: joyrideIds.portfolio.balance,
                steps: [
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.walletOverview
                        },
                        title: t("portfolio.guides.balance.steps.step1.title"),
                        content: t("portfolio.guides.balance.steps.step1.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.balanceValues.container}"]`,
                        spotlightPadding: 40
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.walletOverview
                        },
                        title: t("portfolio.guides.balance.steps.step2.title"),
                        content: t("portfolio.guides.balance.steps.step2.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.balanceValues.overall}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.walletOverview
                        },
                        title: t("portfolio.guides.balance.steps.step3.title"),
                        content: t("portfolio.guides.balance.steps.step3.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.balanceValues.assets}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.walletOverview
                        },
                        title: t("portfolio.guides.balance.steps.step4.title"),
                        content: t("portfolio.guides.balance.steps.step4.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.balanceValues.liquidity}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.walletOverview
                        },
                        title: t("portfolio.guides.balance.steps.step5.title"),
                        content: t("portfolio.guides.balance.steps.step5.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.balanceValues.fees}"]`
                    },
                    {
                        entryUrl: paths.root,
                        entrySearchParams: {
                            tab: PortfolioTab.walletOverview
                        },
                        title: t("portfolio.guides.balance.steps.step6.title"),
                        content: t("portfolio.guides.balance.steps.step6.description"),
                        placement: "bottom",
                        target: `[data-guide-selector="${joyrideSelectors.guides.portfolio.balanceValues.yf}"]`
                    }
                ]
            }
        }), [
        t
    ]);
};
