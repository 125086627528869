import gql from "graphql-tag";
export var Interval;
(function(Interval) {
    Interval["All"] = "All";
    Interval["Daily"] = "Daily";
    Interval["Hourly"] = "Hourly";
    Interval["Minute"] = "Minute";
    Interval["Monthly"] = "Monthly";
    Interval["Raw"] = "Raw";
})(Interval || (Interval = {}));
export var Outcome;
(function(Outcome) {
    Outcome["Cancelled"] = "Cancelled";
    Outcome["Executed"] = "Executed";
})(Outcome || (Outcome = {}));
export var Unit;
(function(Unit) {
    Unit["AssetA"] = "AssetA";
    Unit["AssetB"] = "AssetB";
    Unit["Lovelace"] = "Lovelace";
    Unit["Natural"] = "Natural";
    Unit["Usd"] = "USD";
})(Unit || (Unit = {}));
export var Version;
(function(Version) {
    Version["V1"] = "V1";
    Version["V3"] = "V3";
})(Version || (Version = {}));
export const AssetBrambleFragmentFragmentDoc = gql`
  fragment AssetBrambleFragment on Asset {
    id
    policyId
    description
    dateListed {
      format
    }
    decimals
    ticker
    name
    logo
    assetName
    metadata {
      ... on OnChainLabel20 {
        __typename
      }
      ... on OnChainLabel721 {
        __typename
      }
      ... on CardanoTokenRegistry {
        __typename
      }
    }
  }
`;
export const PoolBrambleFragmentFragmentDoc = gql`
  fragment PoolBrambleFragment on Pool {
    id
    assetA {
      ...AssetBrambleFragment
    }
    assetB {
      ...AssetBrambleFragment
    }
    assetLP {
      ...AssetBrambleFragment
    }
    feesFinalized {
      slot
    }
    marketOpen {
      slot
    }
    askFee
    bidFee
    feeManagerId
    current {
      quantityA {
        quantity
      }
      quantityB {
        quantity
      }
      quantityLP {
        quantity
      }
      tvl {
        quantity
      }
    }
    version
  }
`;
export const OrderBrambleAssetFragmentFragmentDoc = gql`
  fragment OrderBrambleAssetFragment on Asset {
    id
    policyId
    decimals
    ticker
    logo
    assetName: name
  }
`;
export const DetailsAmountFragmentFragmentDoc = gql`
  fragment DetailsAmountFragment on AssetAmount {
    quantity
    asset {
      ...OrderBrambleAssetFragment
    }
  }
`;
export const SwapDetailsFragmentFragmentDoc = gql`
  fragment SwapDetailsFragment on Swap {
    __typename
    minimumSwap: minimum {
      ...DetailsAmountFragment
    }
    offerSwap: offer {
      ...DetailsAmountFragment
    }
    receivedSwap: received {
      ...DetailsAmountFragment
    }
  }
`;
export const DepositDetailsFragmentFragmentDoc = gql`
  fragment DepositDetailsFragment on Deposit {
    __typename
    offerDeposit: offer {
      ...DetailsAmountFragment
    }
    receivedDeposit: received {
      ...DetailsAmountFragment
    }
  }
`;
export const WithdrawDetailsFragmentFragmentDoc = gql`
  fragment WithdrawDetailsFragment on Withdraw {
    __typename
    amountWithdraw: amount {
      ...DetailsAmountFragment
    }
    receivedWithdraw: received {
      ...DetailsAmountFragment
    }
  }
`;
export const OrderBrambleFragmentFragmentDoc = gql`
  fragment OrderBrambleFragment on Order {
    outcome
    createdAt {
      format
    }
    pool {
      ...PoolBrambleFragment
    }
    details {
      ...SwapDetailsFragment
      ...DepositDetailsFragment
      ...WithdrawDetailsFragment
    }
    id
    spentTxHash
    spentAt {
      format
    }
    version
  }
`;
export const LiquidityBrambleFragmentFragmentDoc = gql`
  fragment LiquidityBrambleFragment on Liquidity {
    fees {
      assetA {
        asset {
          ...AssetBrambleFragment
        }
        quantity
      }
      assetB {
        asset {
          ...AssetBrambleFragment
        }
        quantity
      }
    }
    pool {
      ...PoolBrambleFragment
    }
    quantity {
      asset {
        ...AssetBrambleFragment
      }
      quantity
    }
  }
`;
export const TransactionOutputFragmentFragmentDoc = gql`
  fragment TransactionOutputFragment on TxOut {
    fees {
      fees {
        assetA {
          asset {
            ...AssetBrambleFragment
          }
          quantity
        }
        assetB {
          asset {
            ...AssetBrambleFragment
          }
          quantity
        }
      }
      quantity {
        asset {
          ...AssetBrambleFragment
        }
        quantity
      }
      pool {
        ...PoolBrambleFragment
      }
    }
    id
    spentTxHash
  }
`;
export const FetchPoolsByPairDocument = gql`
  query fetchPoolsByPair($assetA: ID!, $assetB: ID!) {
    pools {
      byPair(assetA: $assetA, assetB: $assetB) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPopularPoolsDocument = gql`
  query fetchPopularPools {
    pools {
      popular {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolsBySearchDocument = gql`
  query fetchPoolsBySearch($searchValue: String!) {
    pools {
      search(term: $searchValue) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolByAssetsDocument = gql`
  query fetchPoolByAssets($assets: [ID!]!) {
    pools {
      byAssets(assets: $assets) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolByLpTokensDocument = gql`
  query fetchPoolByLPTokens($assetIds: [ID!]!) {
    pools {
      byLPTokens(assetIds: $assetIds) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchTtPoolByLpTokenDocument = gql`
  query fetchTTPoolByLpToken($assetId: ID!) {
    pools {
      byLPToken(assetId: $assetId) {
        current {
          quantityA {
            quantity
            asset {
              logo
              id
              decimals
              ticker
              assetName
            }
          }
          quantityB {
            quantity
            asset {
              logo
              id
              decimals
              ticker
              assetName
            }
          }
          quantityLP {
            quantity
          }
        }
      }
    }
  }
`;
export const FetchPoolByIdDocument = gql`
  query fetchPoolById($id: ID!) {
    pools {
      byId(id: $id) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolsByIdDocument = gql`
  query fetchPoolsById($ids: [ID!]!) {
    pools {
      byIds(ids: $ids) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolTicksCompactDocument = gql`
  query fetchPoolTicksCompact(
    $id: ID!
    $interval: Interval!
    $start: String!
    $end: String!
  ) {
    pools {
      byIds(ids: [$id]) {
        ticks(interval: $interval, start: $start, end: $end) {
          compact
        }
      }
    }
  }
`;
export const FetchAssetsByIdDocument = gql`
  query fetchAssetsById($ids: [ID!]!) {
    assets {
      byIds(ids: $ids) {
        ...AssetBrambleFragment
      }
    }
  }
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPoolOpenOrdersDocument = gql`
  query fetchPoolOpenOrders($id: ID!) {
    pools {
      byId(id: $id) {
        ...PoolBrambleFragment
        orders {
          open {
            ...OrderBrambleFragment
          }
        }
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${OrderBrambleFragmentFragmentDoc}
  ${SwapDetailsFragmentFragmentDoc}
  ${DetailsAmountFragmentFragmentDoc}
  ${OrderBrambleAssetFragmentFragmentDoc}
  ${DepositDetailsFragmentFragmentDoc}
  ${WithdrawDetailsFragmentFragmentDoc}
`;
export const FetchPoolStatsDocument = gql`
  query fetchPoolStats($id: ID!) {
    pools {
      byId(id: $id) {
        current {
          protocolFees {
            quantity
          }
          tvl {
            quantity
          }
        }
      }
    }
  }
`;
export const FetchProtocolOrdersDocument = gql`
  query fetchProtocolOrders {
    orders {
      id
      version
      createdAt {
        format
      }
      spentAt {
        format
      }
      outcome
      spentTxHash
      pool {
        assetA {
          logo
          ticker
          name
        }
        assetB {
          logo
          ticker
          name
        }
      }
    }
  }
`;
export const FetchOrdersDocument = gql`
  query fetchOrders($address: String!) {
    portfolio(address: $address) {
      orders {
        ...OrderBrambleFragment
      }
    }
  }
  ${OrderBrambleFragmentFragmentDoc}
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${SwapDetailsFragmentFragmentDoc}
  ${DetailsAmountFragmentFragmentDoc}
  ${OrderBrambleAssetFragmentFragmentDoc}
  ${DepositDetailsFragmentFragmentDoc}
  ${WithdrawDetailsFragmentFragmentDoc}
`;
export const FetchProtocolStatsDocument = gql`
  query fetchProtocolStats {
    stats {
      poolCount
      openedOrders
      filledOrders
      tvl {
        quantity
      }
      volume {
        quantity
      }
      protocolRevenue {
        quantity
      }
    }
  }
`;
export const FetchProtocolsDocument = gql`
  query fetchProtocols {
    protocols {
      blueprint {
        validators {
          hash
          title
        }
      }
      version
    }
  }
`;
export const FetchAssetByIdDocument = gql`
  query fetchAssetById($id: ID!) {
    assets {
      byId(id: $id) {
        ...AssetBrambleFragment
      }
    }
  }
  ${AssetBrambleFragmentFragmentDoc}
`;
export const FetchPositionsDocument = gql`
  query fetchPositions($address: String!) {
    portfolio(address: $address) {
      liquidity {
        ...LiquidityBrambleFragment
      }
    }
  }
  ${LiquidityBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${PoolBrambleFragmentFragmentDoc}
`;
export const FetchFeesDocument = gql`
  query fetchFees($ids: [ID!]!) {
    transactionOutput(ids: $ids) {
      ...TransactionOutputFragment
    }
  }
  ${TransactionOutputFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
  ${PoolBrambleFragmentFragmentDoc}
`;
export const FetchPoolByRouteDocument = gql`
  query fetchPoolByRoute($depth: Int!, $givenAsset: ID!, $takenAsset: ID!) {
    pools {
      byRoute(depth: $depth, givenAsset: $givenAsset, takenAsset: $takenAsset) {
        ...PoolBrambleFragment
      }
    }
  }
  ${PoolBrambleFragmentFragmentDoc}
  ${AssetBrambleFragmentFragmentDoc}
`;
const defaultWrapper = (action, _operationName, _operationType)=>action();
export function getSdk(client, withWrapper = defaultWrapper) {
    return {
        fetchPoolsByPair (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolsByPairDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolsByPair", "query");
        },
        fetchPopularPools (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPopularPoolsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPopularPools", "query");
        },
        fetchPoolsBySearch (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolsBySearchDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolsBySearch", "query");
        },
        fetchPoolByAssets (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolByAssetsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolByAssets", "query");
        },
        fetchPoolByLPTokens (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolByLpTokensDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolByLPTokens", "query");
        },
        fetchTTPoolByLpToken (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchTtPoolByLpTokenDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchTTPoolByLpToken", "query");
        },
        fetchPoolById (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolByIdDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolById", "query");
        },
        fetchPoolsById (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolsByIdDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolsById", "query");
        },
        fetchPoolTicksCompact (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolTicksCompactDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolTicksCompact", "query");
        },
        fetchAssetsById (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchAssetsByIdDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchAssetsById", "query");
        },
        fetchPoolOpenOrders (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolOpenOrdersDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolOpenOrders", "query");
        },
        fetchPoolStats (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolStatsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolStats", "query");
        },
        fetchProtocolOrders (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchProtocolOrdersDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchProtocolOrders", "query");
        },
        fetchOrders (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchOrdersDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchOrders", "query");
        },
        fetchProtocolStats (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchProtocolStatsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchProtocolStats", "query");
        },
        fetchProtocols (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchProtocolsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchProtocols", "query");
        },
        fetchAssetById (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchAssetByIdDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchAssetById", "query");
        },
        fetchPositions (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPositionsDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPositions", "query");
        },
        fetchFees (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchFeesDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchFees", "query");
        },
        fetchPoolByRoute (variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders)=>client.request(FetchPoolByRouteDocument, variables, {
                    ...requestHeaders,
                    ...wrappedRequestHeaders
                }), "fetchPoolByRoute", "query");
        }
    };
}
