import { ExchangeModeSteps, ExchangeModeTitles } from "./exchange-mode";
import { OrderTypeSteps, OrderTypeTitles } from "./order-type";
export const Trade = {
    ExchangeMode: {
        Steps: ExchangeModeSteps,
        Titles: ExchangeModeTitles
    },
    OrderType: {
        Steps: OrderTypeSteps,
        Titles: OrderTypeTitles
    }
};
