import { EProfileAppActions } from "./types";
/* -------------------------------------------------------------------------------------------------
 * App
 * -----------------------------------------------------------------------------------------------*/ const setActiveLanguage = (activeLanguage, dispatch)=>dispatch({
        type: EProfileAppActions.SET_ACTIVE_LANGUAGE,
        payload: {
            activeLanguage
        }
    });
const setCardanoExplorer = (cardanoExplorer, dispatch)=>dispatch({
        type: EProfileAppActions.SET_CARDANO_EXPLORER,
        payload: {
            cardanoExplorer
        }
    });
const setErrorReporting = (errorReportingEnabled, dispatch)=>dispatch({
        type: EProfileAppActions.SET_ERROR_REPORTING,
        payload: {
            errorReportingEnabled
        }
    });
const setHotjarEnabled = (hotjarEnabled, dispatch)=>dispatch({
        type: EProfileAppActions.SET_HOTJAR_ENABLED,
        payload: {
            hotjarEnabled
        }
    });
const setProfileEnabled = (profileEnabled, dispatch)=>dispatch({
        type: EProfileAppActions.SET_PROFILE_ENABLED,
        payload: {
            profileEnabled
        }
    });
const setPrivacyEnabled = (privacyEnabled, dispatch)=>dispatch({
        type: EProfileAppActions.SET_PRIVACY_MODE,
        payload: {
            privacyEnabled
        }
    });
const syncDefaultState = (state, dispatch)=>dispatch({
        type: EProfileAppActions.SYNC_DEFAULT_STATE,
        payload: {
            state
        }
    });
const setPreferredHandle = (handle, dispatch)=>dispatch({
        type: EProfileAppActions.SET_PREFERRED_HANDLE,
        payload: {
            handle
        }
    });
const setPreferredAvatar = (avatar, dispatch)=>dispatch({
        type: EProfileAppActions.SET_PREFERRED_AVATAR,
        payload: {
            avatar
        }
    });
const setPrefersReducedMotion = (prefersReducedMotion, dispatch)=>dispatch({
        type: EProfileAppActions.SET_PREFERS_REDUCED_MOTION,
        payload: {
            prefersReducedMotion
        }
    });
/* -------------------------------------------------------------------------------------------------
 * Limit Orders
 * -----------------------------------------------------------------------------------------------*/ const addLimitOrder = (txHash, dispatch)=>dispatch({
        type: EProfileAppActions.ADD_LIMIT_ORDER,
        payload: {
            txHash
        }
    });
/* -------------------------------------------------------------------------------------------------
 * TX Settings
 * -----------------------------------------------------------------------------------------------*/ const setSlippageTolerance = (slippageTolerance, dispatch)=>dispatch({
        type: EProfileAppActions.SET_SLIPPAGE_TOLERANCE,
        payload: {
            slippageTolerance
        }
    });
const setIsSlippageToleranceSetToAuto = (isSlippageToleranceSetToAuto, dispatch)=>dispatch({
        type: EProfileAppActions.SET_IS_SLIPPAGE_TOLERANCE_SET_TO_AUTO,
        payload: {
            isSlippageToleranceSetToAuto
        }
    });
/* -------------------------------------------------------------------------------------------------
 * Farms
 * -----------------------------------------------------------------------------------------------*/ const setFarmsFilter = (farmsFilter, dispatch)=>dispatch({
        type: EProfileAppActions.SET_FARMS_FILTER,
        payload: {
            farmsFilter
        }
    });
const setFarmsViewMode = (farmsViewMode, dispatch)=>dispatch({
        type: EProfileAppActions.SET_FARMS_VIEW_MODE,
        payload: {
            farmsViewMode
        }
    });
/* -------------------------------------------------------------------------------------------------
 * Pool Filters
 * -----------------------------------------------------------------------------------------------*/ const setPoolFilters = (poolFilters, dispatch)=>dispatch({
        type: EProfileAppActions.SET_POOL_FILTERS,
        payload: {
            poolFilters
        }
    });
const addFavoritePool = (poolId, dispatch)=>dispatch({
        type: EProfileAppActions.SET_FAVORITE_POOL,
        payload: {
            poolId
        }
    });
const removeFavoritePool = (poolId, dispatch)=>dispatch({
        type: EProfileAppActions.UNSET_FAVORITE_POOL,
        payload: {
            poolId
        }
    });
/* -------------------------------------------------------------------------------------------------
 * Toggles
 * -----------------------------------------------------------------------------------------------*/ const setToggles = (toggles, dispatch)=>dispatch({
        type: EProfileAppActions.SET_TOGGLES,
        payload: {
            toggles
        }
    });
/* -------------------------------------------------------------------------------------------------
 * Export
 * -----------------------------------------------------------------------------------------------*/ export const PersistentActions = {
    addLimitOrder,
    setActiveLanguage,
    setCardanoExplorer,
    setErrorReporting,
    setFarmsFilter,
    setFarmsViewMode,
    setHotjarEnabled,
    setProfileEnabled,
    setPrivacyEnabled,
    syncDefaultState,
    setIsSlippageToleranceSetToAuto,
    setSlippageTolerance,
    setPreferredAvatar,
    setPreferredHandle,
    setPrefersReducedMotion,
    toggles: {
        setToggles
    },
    filters: {
        pools: {
            addFavoritePool,
            removeFavoritePool,
            setPoolFilters
        }
    }
};
