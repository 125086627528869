export const DEFAULT_INPUT_PLACEHOLDER = "0";
export const MIN_SLIPPAGE_TOLERANCE = 0.01;
export const MAX_SLIPPAGE_TOLERANCE = 100;
export const DEFAULT_SLIPPAGE_TOLERANCE = 3;
export const MAX_SLIPPAGE_PCT_SUCCESS = 0.5;
export const MAX_SLIPPAGE_PCT_WARNING = 6.25;
export const MAX_PRICE_IMPACT_SUCCESS = 1;
export const MAX_PRICE_IMPACT_WARNING = 3;
export const MAX_PRICE_IMPACT_CHECKBOX_CONFIRMATION = 0.1;
export const MAX_LP_FEE_THRESHOLD = 5;
export const MAX_LIQUIDITY_FEE_PER_TEN_THOUSAND = 5000;
