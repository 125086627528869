import { ExplanationGuideSteps, ExplanationGuideTitles } from "./explanation-guide";
import { InteractiveGuideSteps, InteractiveGuideTitles } from "./interactive-guide";
export const TasteTest = {
    Explanation: {
        Steps: ExplanationGuideSteps,
        Titles: ExplanationGuideTitles
    },
    Interactive: {
        Steps: InteractiveGuideSteps,
        Titles: InteractiveGuideTitles
    }
};
