export const IDB_SUNDAE = "SUNDAE_DEX_DB";
export const IDB_STORE_KEY_WALLETS = "wallets";
export const IDB_STORE_KEY_ASSETS = "assets";
export const IDB_STORE_VERSION = 2;
export const openDatabase = ()=>{
    return new Promise((resolve, reject)=>{
        const request = window.indexedDB.open(IDB_SUNDAE, IDB_STORE_VERSION);
        request.onupgradeneeded = function() {
            const walletStore = this.result.createObjectStore(IDB_STORE_KEY_WALLETS, {
                keyPath: "address"
            });
            const assetStore = this.result.createObjectStore(IDB_STORE_KEY_ASSETS, {
                keyPath: "assetId"
            });
            walletStore.createIndex("address", "address", {
                unique: true
            });
            assetStore.createIndex("assetId", "assetId", {
                unique: true
            });
        };
        request.onsuccess = function() {
            resolve(this.result);
        };
        request.onerror = function() {
            reject(this.error);
        };
    });
};
export const getCachedAssetData = async ()=>{
    const db = await openDatabase();
    return new Promise((resolve, reject)=>{
        const store = db.transaction([
            IDB_STORE_KEY_ASSETS
        ], "readonly").objectStore(IDB_STORE_KEY_ASSETS);
        const request = store.getAll();
        request.onsuccess = function() {
            const serializedResult = this.result;
            if (serializedResult.length === 0) {
                resolve(null);
            }
            const deserializedResult = new Map(serializedResult.map((m)=>[
                    m.assetId,
                    m
                ]));
            resolve(deserializedResult);
        };
        request.onerror = function() {
            reject(this.error);
        };
    }).catch((e)=>{
        console.log(e);
        return null;
    });
};
export const saveCachedAssetData = async (metadata)=>{
    const db = await openDatabase();
    return new Promise((resolve, reject)=>{
        const transaction = db.transaction([
            IDB_STORE_KEY_ASSETS
        ], "readwrite");
        const objectStore = transaction.objectStore(IDB_STORE_KEY_ASSETS);
        [
            ...metadata.values()
        ].forEach((asset)=>{
            const request = objectStore.put(asset);
            request.onsuccess = function() {
                resolve(null);
            };
            request.onerror = function() {
                console.error(`Error saving asset with ID ${asset.assetId}: `, this.error);
            };
        });
        transaction.oncomplete = function() {
            resolve(null);
        };
        transaction.onerror = function() {
            reject(this.error);
        };
    });
};
export const clearCachedAssetData = async ()=>{
    const db = await openDatabase();
    return new Promise((resolve, reject)=>{
        const transaction = db.transaction([
            IDB_STORE_KEY_ASSETS
        ], "readwrite");
        const objectStore = transaction.objectStore(IDB_STORE_KEY_ASSETS);
        const request = objectStore.clear();
        request.onsuccess = function() {
            resolve(true);
        };
        request.onerror = function() {
            reject(this.error);
        };
    });
};
