import { useQuery } from "@tanstack/react-query";
import axios from "axios";
export const useServerTime = ()=>{
    return useQuery({
        queryKey: [
            "time"
        ],
        queryFn: async ()=>{
            const { data } = await axios.get(window.__APP_CONFIG.apiUrls.time);
            return data;
        },
        staleTime: 1000 * 60,
        gcTime: 1000 * 60
    });
};
